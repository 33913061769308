<script>
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import { listOrigens } from "@/components/defaults/origens";
import { listCFOPs } from "@/components/defaults/cfops";
//import modelComposicao from "@/model/composicao.js";
import modelIntegracaoFornecedor from "@/model/integracao_fornecedor.js";
// import modelTabelaPreco from "@/model/tabela_preco.js";

export default {
  page: {
    title: "Incluir Produto",
    meta: [{ name: "description", content: appConfig.description }],
  },
  props: {
    currentEmpresa: {},
    newProduto: { type: Object },
    listTabelas: { type: Object },
    listImpostos: { type: Object },
    listProdutos: { type: Array },
    vet_uf_icms: { type: Array },
    vet_loc_estoques: { type: Array },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      select_local_estoque_id: null,
      locais_estoques: [],
      vet_uf_icms_new_objeto: [],
      array_all_icms_ufs: [],
      tabs_icms: [],
      fields_icms: [
        // {
        //   label: "Código",
        //   key: "id",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        {
          label: "Uf",
          key: "uf",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "%ICMS",
          key: "icms",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "%Red base Calc",
          key: "red_base",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Margem",
          key: "margem",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Diferimento",
          key: "diferimento",
          tdClass: "text-center",
          thClass: "text-center",
        },
        // {
        //   label: "Vlr Bruto",
        //   key: "bruto",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        // {
        //   label: "Desconto",
        //   key: "desconto",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },

        // {
        //   label: "Ativo",
        //   key: "ativo",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        // { label: "Número", key: "numero", tdClass: 'text-center', thClass: 'text-center' },
        // { label: "Série", key: "serie", tdClass: 'text-center', thClass: 'text-center', formatter: "formatterCurrBR" },
        // { label: "Criado", key: "data_criacao", tdClass: 'text-right', thClass: 'text-center' },
        {
          // label: "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0Ações\u00A0\u00A0\u00A0",
          label: "Acões",
          key: "excluir",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      text: "Necessário gravar o produto antes de informar a composição",
      loader: {
        get: false,
      },
      preco_total: null,
      submitted: false,
      inserindo: false,
      produto: {},
      custo_medio: null,
      grupoSelecionado: null,
      unidadeSelecionada: null,
      tipoProdutoSelecionado: null,
      origSelecionado: null,
      cfopSelecionado: null,
      CSTICMSSelecionado: null,
      CSTIPISelecionado: null,
      CSTPISSelecionado: null,
      CSTCOFINSSelecionado: null,
      CSTICMSeSelecionado: null,
      CSTIPIeSelecionado: null,
      CSTPISeSelecionado: null,
      CSTCOFINSeSelecionado: null,
      classificacaoSPEDSelecionado: null,
      listOrigem: [],
      listCFOP: [],
      listGrupos: [],
      listUnidades: [],
      listTiposProduto: [],
      listClassificacaoSPED: [],
      listCSTsICMS: [],
      listCSTsIPI: [],
      listCSTsPIS: [],
      listCSTsCOFINS: [],
      listProdutosCompor: [],
      listFornecedores: [],
      listTabelasPreco: [],
      composicao: {},
      composicaoIDX: -1,
      integracaoFornecedorIDX: -1,
      integracaoFornecedor: {},
      tabelaPrecoIDX: -1,
      tabelaPreco: {},
      comp: ["1", "1", "1"],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fieldsComposisoes: [
        // {
        //   label: "Código",
        //   key: "id",
        //   sortable: true,
        //   tdClass: "text-right",
        //   thClass: "text-center",
        // },
        {
          label: "Código Produto",
          key: "cod_produto",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Nome do Produto",
          key: "produto.produto_nome",
          sortable: true,
          thClass: "text-center",
        },
        {
          label: "Unidade",
          key: "um",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Custo R$",
          key: "custo",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
          formatter: "formatterCurrBR",
        },
        {
          label: "Preço R$",
          key: "venda",
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
          formatter: "formatterCurrBR",
        },
        {
          label: "Quantidade",
          key: "quantidade",
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Ações",
          key: "acoes",
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      fieldsIntegracaoFornecedor: [
        {
          label: "Código",
          key: "id",
          sortable: true,
          tdClass: "text-right",
          thClass: "text-center",
        },
        {
          label: "Fornecedor",
          key: "fornecedor.pes_apelido",
          sortable: true,
          tdClass: "text-left",
          thClass: "text-center",
        },
        {
          label: "Código no Fornecedor",
          key: "codigo",
          sortable: true,
          tdClass: "text-right",
          thClass: "text-center",
        },
        {
          label: "Descrição no Fornecedor",
          key: "descricao",
          sortable: true,
          tdClass: "text-left",
          thClass: "text-center",
        },
        {
          label: "Fator",
          key: "fator",
          sortable: true,
          tdClass: "text-right",
          thClass: "text-center",
        },
      ],
      cson_vet: [],
      xml_text: null,
      fileContent: null,
    };
  },
  validations() {
    return {
      produto: {
        codbarras: { required },
        idtipo: { required },
        produto_m_s: { required },
        produto_nome: { required },
        produto_descricao: { required },
        produto_ncm: { required },
        preco_custo: { required },
        preco: { required },
        produto_margemlucro: { required },
        unidade_id: { required },
        qtd: { required },
        grupo_id: { required },
        produto_orig: { required },
        produto_cst: { required },
        produto_cstpis: { required },
        produto_cstcofins: { required },
        produto_cstipi: { required },
        produto_cfop: { required },
        produto_class_sped: { required },
        produto_pesoliq: { required },
        produto_pesobruto: { required },
        produto_fatexport: { required },
      },
    };
  },
  created() {
    //setFake()
    this.array_all_icms_ufs = this.vet_uf_icms;
    this.locais_estoques = this.vet_loc_estoques
  },
  mounted() {
    //console.log(this.listProdutos)
    this.setNewTitle();
    console.clear;
    this.produto = this.newProduto;
    this.produto.composicoes = [];
    this.produto.tabelaPrecos = [];
    this.tabelaPreco.produto_id = this.produto.id;
    // this.composicao = new modelComposicao(this.composicaoIDX, this.produto.id);
    this.produto.integracaoFornecedores = [];
    this.integracaoFornecedor = new modelIntegracaoFornecedor(
      this.integracaoFornecedorIDX,
      this.produto.id
    );
    this.listOrigem = listOrigens;
    this.listCFOP = listCFOPs;
    var grupos = this.listTabelas.grupos;
    this.listGrupos = grupos.map((item) => {
      return {
        name: item.descricaogrupo,
        id: item.id,
      };
    });
    var unidades = this.listTabelas.unidades;
    this.listUnidades = unidades.map((item) => {
      return {
        name: item.DescricaoUn,
        id: item.id,
      };
    });
    var tiposProduto = this.listTabelas.tiposProduto;
    this.listTiposProduto = tiposProduto.map((item) => {
      return {
        name: item.descricao,
        id: item.id,
      };
    });
    var classificacaoSPED = this.listTabelas.classificacaoSPED;
    this.listClassificacaoSPED = classificacaoSPED.map((item) => {
      return {
        name: item.clasped_descricao,
        id: item.id,
      };
    });
    var CSTsICMS = this.listImpostos.icms;
    this.listCSTsICMS = CSTsICMS.map((item) => {
      return {
        name: item.descricao,
        id: parseInt(item.cst),
      };
    });
    var CSTsIPI = this.listImpostos.ipi;
    this.listCSTsIPI = CSTsIPI.map((item) => {
      return {
        name: item.descricao,
        id: item.cst,
      };
    });
    var CSTsPIS = this.listImpostos.pis;
    this.listCSTsPIS = CSTsPIS.map((item) => {
      return {
        name: item.descricao,
        id: item.cst,
      };
    });
    var CSTsCOFINS = this.listImpostos.cofins;
    this.listCSTsCOFINS = CSTsCOFINS.map((item) => {
      return {
        name: item.descricao,
        id: item.cst,
      };
    });
    // this.$refs.codbarras.focus();

    this.listProdutosCompor = this.listProdutos.filter(
      (produto) => produto.produto_materiaprima === -1 && produto.ativo === -1
    );
    this.listFornecedores = this.listTabelas.fornecedores;
    this.listTabelasPreco = this.listTabelas.tiposTabelasPreco;

    for (let index = 0; index < this.listTabelasPreco.length; index++) {
      var tabela = this.listTabelasPreco[index];
      this.tabelaPreco[tabela.ttp_nome] = 0;
      this.tabelaPreco[tabela.ttp_nome + "Moeda"] = "0,00";
    }

    this.tabelaPreco.produto_id = this.produto.id;
    this.tabelaPreco.id = this.tabelaPrecoIDX;
    this.set_values();
    this.valor_CSON();
    // console.log("Mostrando impostos")
    // console.log(this.listImpostos)
    // console.log(this.classificacaoSPED)
    // console.log(this.listTabelas)
    // console.log(this.currentEmpresa)

    this.new_vet_uf_icms();
  },
  computed: {
    rowsComposicoes() {
      return this.produto.composicoes ? this.produto.composicoes.length : 0;
    },
    cloneFootComposicoes() {
      if (this.produto.composicoes) {
        return this.produto.composicoes.length ||
          this.produto.composicoes.length > 10
          ? true
          : false;
      }

      return false;
    },
    CSTsFilter() {
      return this.listCSTsICMS.filter((valor) => {
        return this.currentEmpresa.crt === 1 ? valor.id >= 100 : valor.id < 100;
      });
    },
  },
  directives: {
    percent: {
      updated(el) {
        el.value = el.value + "%";
      },
    },
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    // função usada para chamar o  handleFileChange
    selectFile2() {
      document.getElementById("fileInput").click();
    },
    selecona_produzido(event){
     console.log(event)
      if(event == -1){
        this.produto.produto_controla_estoque = 0
      }
    },
    seleciona_local_estoque(event){
       console.log(event)

       this.produto.produto_local_estoque_id = event.target.value
       console.log(this.produto.produto_local_estoque_id)
    },
    abr_local_estoque(event) {
      console.log(event);
    },
    new_vet_uf_icms() {
      console.log("mostrando todos os impostos de ufs no insert");
      console.log(this.array_all_icms_ufs);
      console.log(this.currentEmpresa.uf);
      let selecionado_uf_icms = this.array_all_icms_ufs.find(
        (uf) => uf.uficms_uf == this.currentEmpresa.uf
      );
      console.log(selecionado_uf_icms);

      const transformedArray = Object.keys(selecionado_uf_icms)
        .filter((key) => key.startsWith("uficms_"))
        .map((key) => {
          return {
            //
            uficms_uf: key.split("_")[1].toUpperCase(),
            uficms_value: selecionado_uf_icms[key],
          };
        });

      console.log(transformedArray);
      this.vet_uf_icms_new_objeto = transformedArray;
    },
    dlt_icms(objeto, index) {
      console.log(objeto);
      console.log(index);
      if (this.tabs_icms.length > 1) {
        this.tabs_icms.splice(index, 1);
      }
      console.log(this.tabs_icms);
    },
    add_icms() {
      this.tabs_icms = [];
      this.vet_uf_icms_new_objeto.forEach((item) => {
        console.log(item.uficms_uf);
        if (item.uficms_uf != "uf") {
          let objeto = {
            uf: item.uficms_uf,
            icms: item.uficms_value,
            red_base: null,
            margem: null,
            diferimento: null,
          };
          console.log(objeto);
          this.tabs_icms.push({ ...objeto });
        }
      });
      // let objeto = {
      //   uf: null,
      //   icms: null,
      //   red_base: null,
      //   margem: null,
      //   diferimento: null,
      // };
      this.produto.vet_uf_icms_new_objeto = this.vet_uf_icms_new_objeto;
      this.produto.tabs_icms_new = this.tabs_icms;
      console.log(this.tabs_icms);
    },
    handleFileChange(event) {
      const file = event.target.files[0];

      if (file) {
        this.readFileContent(file)
          .then((content) => {
            this.fileContent = content;
            this.ler_xml();
          })
          .catch((error) => {
            console.error("Erro ao ler o conteúdo do arquivo:", error);
          });
      }
    },

    readFileContent(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (e) => {
          resolve(e.target.result);
        };

        reader.onerror = (e) => {
          reject(e.target.error);
        };

        reader.readAsText(file);
      });
    },

    ler_xml() {
      // console.log("estou em ler xml ");
      console.log(this.fileContent);
      if (typeof this.fileContent === "string") {
        console.log("é uma srting");
      }
      // xml em string
      let string_xml = this.fileContent;
      // varivel que vai receber o conteudo convertido de string para xml
      let xmlDocument = new DOMParser().parseFromString(string_xml, "text/xml");
      console.log(xmlDocument);
      let nome = xmlDocument.querySelector("xProd");
      let NCM = xmlDocument.querySelector("NCM");
      let GTIN = xmlDocument.querySelector("cEAN");

      console.log(nome.textContent);
      let nome_em_srting = nome.textContent.toString();
      let NCM_em_srting = NCM.textContent.toString();
      let GTIN_em_srting = GTIN.textContent.toString();
      console.log("Gtin" + GTIN_em_srting);
      this.produto.produto_nome = nome_em_srting;
      this.produto.produto_ncm = NCM_em_srting;
      this.produto.produto_descricao = nome_em_srting;
      this.produto.produto_cean = GTIN_em_srting;
    },
    valor_CSON() {
      let valor = this.listCSTsICMS.filter((valor) => {
        return this.currentEmpresa.crt === 1 ? valor.id >= 100 : valor.id < 100;
      });
      this.cson_vet = valor;
      this.CSTICMSSelecionado = valor[0];
      this.produto.produto_cst = "0";
      console.log("CSON");
      console.log(valor);
    },
    set_values() {
      // this.produto.preco = "1,40"
      this.produto.id = -1;
      this.produto.preco = "1,00";
      this.produto.qtd = 1;
      this.produto.preco_custo = "1,00";
      this.produto.produto_margemlucro = "1,00";
      this.produto.preco_atacado = "1,00";
      this.produto.qtd = 1;
      this.origSelecionado = this.listOrigem[0];
      this.produto.produto_orig = this.listOrigem[0].id;
      this.cfopSelecionado = listCFOPs[288];
      this.produto.produto_cfop = listCFOPs[288].id;
      this.produto.produto_picms = "0";
      this.produto.produto_pbc_icms = "0";
      this.produto.produto_picmsst = "0";
      this.produto.produto_pbc_icmsst = "0";
      this.produto.produto_pbc_ipi = "0";
      this.produto.produto_pipi = "0";
      this.produto.produto_pbc_pis = "0";
      this.produto.produto_ppis = "0";
      this.produto.produto_pbc_cofins = "0";
      this.produto.produto_pcofins = "0";
      this.produto.produto_ppisst = "0";
      this.produto.produto_pBC_pisst = "0";
      this.produto.produto_pbc_cofinsst = "0";
      this.produto.produto_pcofinsst = "0";
      this.produto.produto_cste = "0";
      this.produto.produto_picmse = "0";
      this.produto.produto_pbc_icmse = "0";
      this.produto.produto_picmsste = "0";
      this.produto.produto_pbc_icmsste = "0";
      this.produto.produto_cenqe = "0";
      this.produto.produto_pipie = "0";
      this.produto.produto_pbc_ipie = "0";
      this.produto.produto_ppise = "0";
      this.produto.produto_pbc_pise = "0";
      this.produto.produto_ppisste = "0";
      this.produto.produto_pBC_pisste = "0";
      this.produto.produto_pcofinse = "0";
      this.produto.produto_pbc_cofinse = "0";
      this.produto.produto_pcofinsste = "0";
      this.produto.produto_pbc_cofinsste = "0";
      this.produto.produto_cenq = "0";

      //
      this.produto.produto_cste = "0";
      this.produto.produto_picmse = "0";
      this.produto.produto_pbc_icmse = "0";
      this.produto.produto_picmsste = "0";
      this.produto.produto_pbc_icmsste = "0";
      this.produto.produto_cenqe = "0";
      this.produto.produto_pipie = "0";
      this.produto.produto_pbc_ipie = "0";
      this.produto.produto_ppise = "0";
      this.produto.produto_pbc_pise = "0";
      this.produto.produto_ppisste = "0";
      this.produto.produto_pBC_pisste = "0";
      this.produto.produto_pcofinse = "0";
      this.produto.produto_pbc_cofinse = "0";
      this.produto.produto_pcofinsste = "0";
      this.produto.produto_pbc_cofinsste = "0";

      this.classificacaoSPEDSelecionado = this.listClassificacaoSPED[0];
      this.produto.produto_class_sped = this.listClassificacaoSPED[0].id;
      this.produto.produto_pesoliq = "0,100";
      this.produto.produto_pesobruto = "0,100";
      this.produto.produto_fatexport = "1,00";
      this.produto.produto_cod_int = "0001";
      this.produto.produto_cta = "123";
      this.produto.produto_intdominio = "000001";

      this.CSTIPISelecionado = this.listCSTsIPI[0];
      this.produto.produto_cstipi = this.listCSTsIPI[0].id;

      this.CSTPISSelecionado = this.listCSTsPIS[0];
      this.produto.produto_cstpis = this.listCSTsPIS[0].id;

      this.CSTCOFINSSelecionado = this.listCSTsCOFINS[0];
      this.produto.produto_cstcofins = this.listCSTsCOFINS[0].id;

      this.CSTICMSeSelecionado = this.listCSTsICMS[0];
      this.produto.produto_cste = this.listCSTsICMS[0].id;

      this.CSTIPIeSelecionado = this.listCSTsIPI[0];
      this.produto.produto_cstipie = this.listCSTsIPI[0].id;

      this.CSTPISeSelecionado = this.listCSTsPIS[0];
      this.produto.produto_cstpise = this.listCSTsPIS[0].id;

      this.CSTCOFINSeSelecionado = this.listCSTsCOFINS[0];
      this.produto.produto_cstcofinse = this.listCSTsCOFINS[0].id;
      this.produto.produto_cod = 0;

      /// this.setFake();
    },
    customLabelComposicao({ codbarras, produto_nome }) {
      return `${codbarras} – ${produto_nome}`;
    },
    customLabelFornecedor({ id, pes_apelido }) {
      return `${id} – ${pes_apelido}`;
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    setNewTitle() {
      this.$emit("newTitle", "Cadastrar Produto");
    },
    calcPreco() {
      this.produto.produto_margemlucro = this.produto.produto_margemlucro
        ? this.produto.produto_margemlucro.replace("%", "")
        : 0;
      if (
        this.produto.preco_custo.includes(".") &&
        this.produto.preco_custo.includes(",")
      ) {
        var custo = parseFloat(
          this.produto.preco_custo.replace(".", "").replace(",", ".")
        );
        //console.log(this.produto.preco_custo)
      } else if (
        !this.produto.preco_custo.includes(".") &&
        this.produto.preco_custo.includes(",")
      ) {
        custo = parseFloat(this.produto.preco_custo.replace(",", "."));
      } else {
        // console.log(this.produto.preco_custeo)

        //    let tamanho = this.produto.preco_custo.length
        //    let numero =  this.produto.preco_custo.replace
        //    let vet = [
        //    ]

        //    for(let i =0 ; i < tamanho; i++){

        //    let numericValue =

        //    custo = parseFloat(numericValue / 100)
        //   .toLocaleString("pt-BR", {
        //     style: "currency",
        //     currency: "BRL",
        //   })
        //   .replace("R$", " ");
        // }

        console.log(custo);
        custo = this.produto.preco_custo + ",00";
        // console.log(this.formatterCurrBR(custo))
        if (typeof custo === "string") {
          if (custo.includes(".") && custo.includes(",")) {
            custo = parseFloat(custo.replace(".", "").replace(",", "."));
          }
        }
        // console.log(this.produto.preco_custo)
      }
      if (
        this.produto.preco_custo &&
        parseFloat(this.produto.preco_custo) > 0
      ) {
        if (
          this.produto.produto_margemlucro &&
          parseFloat(this.produto.produto_margemlucro) > 0
        ) {
          ///console.log(custo)
          // console.log(this.produto.produto_margemlucro)

          var margem = parseFloat(
            this.produto.produto_margemlucro.replace(",", ".")
          );
          ///console.log(this.produto.produto_margemlucro)
          // var preco =  (((margem + 100) / 100) * custo);

          var preco = custo * ((margem + 100) / 100);
          //console.log(preco)
          //this.produto.preco = this.formatterCurrBR(preco.toFixed(2).replace(".", ","));
          this.produto.preco = preco;
          //this.produto.preco_custo = this.formatterCurrBR(this.produto.preco_custo)
          this.preco_total = this.formatterCurrBR(preco);
        }
      }
      this.produto.preco_custo = this.formatterCurrBR(this.produto.preco_custo);

      this.produto.produto_margemlucro = this.produto.produto_margemlucro + "%";
    },
    inputComposicaoQuantidade(value) {
      let newValue;
      newValue = this.formatInputFloat(value);

      this.composicao.quantidadeFloat = newValue;
      this.composicao.quantidade =
        this.coverte_para_float_valor_input(newValue);
    },
    inputComposicaoCusto(value) {
      let newValue;
      newValue = this.formatInputFloat(value);

      this.composicao.custoMoeda = newValue;
      this.composicao.custo = this.coverte_para_float_valor_input(newValue);
    },
    inputComposicaoVenda(value) {
      let newValue;
      newValue = this.formatInputFloat(value);

      this.composicao.vendaMoeda = newValue;
      this.composicao.venda = this.coverte_para_float_valor_input(newValue);
    },
    inputTabelaPreco(value, fieldName) {
      let newValue;
      newValue = this.formatInputFloat(value);

      this.tabelaPreco[fieldName + "Moeda"] = newValue;
      this.tabelaPreco[fieldName] =
        this.coverte_para_float_valor_input(newValue);
    },
    compositorSelecionado(event) {
      this.composicao.cod_produto = event.id;
      console.log(event.um);
      this.composicao.custo = this.formatterFloatBR(event.preco_custo);
      this.composicao.venda = this.formatterFloatBR(event.preco);
      this.composicao.quantidade = this.formatterFloatBR(event.qtd);
      this.composicao.um = event.um;

      this.composicao.custoMoeda = this.formatterFloatBR(event.preco_custo);
      this.composicao.vendaMoeda = this.formatterFloatBR(event.preco);
    },
    fornecedorSelecionado(event) {
      this.integracaoFornecedor.fornecedor_id = event.id;
    },
    back() {
      this.$emit("back");
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.produto.$error) {
        this.makeToast("danger", "Erro de validação!");
      } else {
        // this.converte_valores_composicoes();
        /// console.log(this.produto.composicoes);
        this.setPost();
      }
    },
    converte_valores_composicoes() {
      // this.composicao.custo = this.new_convert_srting_float(this.composicao.custo);
      // this.composicao.venda = this.new_convert_srting_float(this.composicao.venda);
      // this.composicao.quantidade = this.new_convert_srting_float(this.composicao.quantidade)

      this.produto.composicoes.forEach((produto, index) => {
        let custo = this.new_convert_srting_float(produto.custo);
        let venda = this.new_convert_srting_float(produto.venda);
        let quantidade = this.new_convert_srting_float(produto.quantidade);
        this.produto.composicoes[index].custo = custo;
        this.produto.composicoes[index].venda = venda;
        this.produto.composicoes[index].quantidade = quantidade;
      });
      console.log("estou no covnverte valores das composições");
      console.log(this.produto.composicoes);
    },

    enviar() {
      let composicao = { ...this.composicao };
      // console.log(composicao);
      this.produto.composicoes.push(composicao);

      // let newComposicao = new modelComposicao(
      //   this.composicao.id,
      //   this.composicao.cod_produto,
      //   this.composicao.produto,
      //   this.composicao.quantidade,
      //   this.composicao.custo,
      //   this.composicao.venda,
      //   this.produto.id
      // );
      //this.produto.composicoes.push(newComposicao);
    },
    addFornecedorIntegracao() {
      let newFonecedorIntegracao = new modelIntegracaoFornecedor(
        this.integracaoFornecedor.id,
        this.integracaoFornecedor.cod_produto,
        this.integracaoFornecedor.fornecedor_id,
        this.integracaoFornecedor.fornecedor,
        this.integracaoFornecedor.codigo,
        this.integracaoFornecedor.descricao,
        this.integracaoFornecedor.fator
      );
      this.produto.integracaoFornecedores.push(newFonecedorIntegracao);
      this.integracaoFornecedor = new modelIntegracaoFornecedor(
        --this.composicaoIDX,
        -1,
        null,
        null,
        null,
        null,
        1
      );
    },
    addTabelaPreco() {
      this.produto.tabelaPrecos.push(this.tabelaPreco);
    },
    delTabelaPreco(item) {
      var index = this.produto.tabelaPrecos.indexOf(item);
      console.log(item);

      if (index > -1) {
        this.produto.tabelaPrecos.splice(index, 1);
      }
    },
    setDelete(item) {
      var index = this.produto.composicoes.indexOf(item);

      if (index > -1) {
        this.produto.composicoes.splice(index, 1);
      }
    },
    converte_values_tabs_icms() {
      console.log("Estou em Converter Tabs");
      let vetor_original = [...this.tabs_icms];
      console.log("mostrando vetor original");
      console.log(vetor_original);

      vetor_original.forEach((item, index) => {
        let red_base = this.new_convert_srting_float(item.red_base);
        let margem = this.new_convert_srting_float(item.margem);
        let diferimento = this.new_convert_srting_float(item.diferimento);

        this.tabs_icms[index].red_base = red_base;
        this.tabs_icms[index].margem = margem;
        this.tabs_icms[index].diferimento = diferimento;
      });
      console.log("mostrando vetor com valores convertidos");
      console.log(this.tabs_icms);
    },
    setPost() {
      this.converte_values_tabs_icms();
      console.log("mostrando array no post de produtos");

      this.produto.tabs_icms_new = this.tabs_icms;
      console.log(this.produto.tabs_icms_new);
      console.log("mostrando produto cod");
      console.log(this.produto.produto_cod);
      if (
        this.produto.produto_ncm != null &&
        this.produto.produto_ncm != undefined &&
        this.produto.produto_ncm != ""
      ) {
        if (this.produto.produto_ncm.length != 8) {
          this.makeToast("danger", "o NCM tem conter 8 Caracteres");
        } else if (this.produto.produto_ncm.length == 8) {
          this.converte_valores_composicoes();
          this.inserindo = !this.inserindo;
          // this.produto.preco_custo.replace(",", ".")
          let valor_custo = parseFloat(this.produto.preco_custo);
          // console.log(valor_custo)
          let srting_custo = valor_custo.toString();
          this.produto.preco_custo = srting_custo;

          if (
            this.produto.preco_atacado.includes(".") &&
            this.produto.preco_atacado.includes(",")
          ) {
            let valor_atacado = this.produto.preco_atacado
              .replace(".", "")
              .replace(",", ".");
            let Valor_atacado_float = parseFloat(valor_atacado);
            this.produto.preco_atacado = Valor_atacado_float.toString();
          } else if (
            !this.produto.preco_atacado.includes(".") &&
            this.produto.preco_atacado.includes(",")
          ) {
            let valor_atacado = this.produto.preco_atacado.replace(",", ".");
            let Valor_atacado_float = parseFloat(valor_atacado);
            this.produto.preco_atacado = Valor_atacado_float.toString();
          }

          this.$emit("doPost", this.produto);
        }
      } else {
        this.makeToast("danger", "Insira o NCM");
      }
    },
    selectGrupo(value) {
      this.produto.grupo_id = value ? value.id : null;
    },
    selectUnidade(value) {
      this.produto.unidade_id = value ? value.id : null;
      this.produto.um = value ? value.name : null;
    },
    selectTipoProduto(value) {
      this.produto.idtipo = value ? value.id : null;
    },
    selectOrig(value) {
      this.produto.produto_orig = value ? value.id : null;
    },
    selectCFOP(value) {
      this.produto.produto_cfop = value ? value.id : null;
    },
    // Impostos Saídas
    selectCST(value) {
      this.produto.produto_cst = value ? value.id : null;
    },
    selectCSTIPI(value) {
      this.produto.produto_cstipi = value ? value.id : null;
    },
    selectCSTPIS(value) {
      this.produto.produto_cstpis = value ? value.id : null;
    },
    selectCSTCOFINS(value) {
      this.produto.produto_cstcofins = value ? value.id : null;
    },
    // Impostos Entradas
    selectCSTe(value) {
      this.produto.produto_cste = value ? value.id : null;
    },
    selectCSTIPIe(value) {
      this.produto.produto_cstipie = value ? value.id : null;
    },
    selectCSTPISe(value) {
      this.produto.produto_cstpise = value ? value.id : null;
    },
    selectCSTCOFINSe(value) {
      this.produto.produto_cstcofinse = value ? value.id : null;
    },
    selectClassificacaoSPED(value) {
      this.produto.produto_class_sped = value ? value.id : null;
    },
    setFake() {
      this.produto.codbarras = "1234";
      this.produto.produto_nome = "PRODUTO MODELO";
      this.produto.produto_descricao = "PRODUTO MODELO";
      this.produto.idtipo = 1;
      this.produto.produto_m_s = "M";
      this.produto.produto_ncm = "123456";
      this.produto.produto_cest = "12345";
      this.produto.preco_custo = "1,00";
      this.produto.preco = "1,40";
      this.produto.produto_margemlucro = "40,00";
      this.produto.preco_atacado = "1,30";
      this.produto.unidade_id = 5;
      this.produto.um = "UNIDADE";
      this.produto.qtd = 1; // Gerar estoque no local padrão
      this.produto.ativo = -1;
      this.produto.produto_controla_estoque = -1;
      this.produto.produto_balanca = 0;
      this.produto.produto_produzido = 0;
      this.produto.grupo_id = 1;
      this.produto.produto_cean = "SEM GTIN";
      this.produto.produto_orig = 1;
      this.produto.produto_cst = "102";
      this.produto.produto_cstpis = "08";
      this.produto.produto_cstcofins = "08";
      this.produto.produto_cstipi = "53";
      this.produto.produto_picms = "18";
      this.produto.produto_pbc_icms = "100";
      this.produto.produto_cfop = "5102";
      this.produto.produto_picmsst = "0";
      this.produto.produto_pbc_icmsst = "0";
      this.produto.produto_cenq = "999";
      this.produto.produto_pbc_ipi = "0";
      this.produto.produto_pipi = "0";
      this.produto.produto_pbc_pis = "0";
      this.produto.produto_ppis = "0";
      this.produto.produto_pbc_cofins = "0";
      this.produto.produto_pcofins = "0";
      this.produto.produto_ppisst = "0";
      this.produto.produto_pBC_pisst = "0";
      this.produto.produto_pbc_cofinsst = "0";
      this.produto.produto_pcofinsst = "0";
      this.produto.produto_cste = null;
      this.produto.produto_picmse = "0";
      this.produto.produto_pbc_icmse = "0";
      this.produto.produto_picmsste = "0";
      this.produto.produto_pbc_icmsste = "0";
      this.produto.produto_cenqe = null;
      this.produto.produto_pipie = "0";
      this.produto.produto_pbc_ipie = "0";
      this.produto.produto_ppise = "0";
      this.produto.produto_pbc_pise = "0";
      this.produto.produto_ppisste = "0";
      this.produto.produto_pBC_pisste = "0";
      this.produto.produto_pcofinse = "0";
      this.produto.produto_pbc_cofinse = "0";
      this.produto.produto_pcofinsste = "0";
      this.produto.produto_pbc_cofinsste = "0";
      this.produto.produto_class_sped = 4;
      this.produto.produto_pesoliq = "0,100";
      this.produto.produto_pesobruto = "0,100";
      this.produto.produto_fatexport = "1,00";
      this.produto.produto_cod_int = "0001";
      this.produto.produto_cta = "123";
      this.produto.produto_intdominio = "000001";
      this.produto.produto_cod = "1234";
    },
    formatInput_valor() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.produto.preco_custo.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.produto.preco_custo = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
      ///this.calcPreco()
    },
    formatInput_valor_atacado() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.produto.preco_atacado.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.produto.preco_atacado = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
      //this.calcPreco()
    },
  },
};
</script>
<!--  -->
<template>
  <div class="card">
    <div class="accordion" role="tablist">
      <form role="form" class="form-horizontal">
        <b-card no-body class="mb-1">
          <div
            class="row d-flex justify-content-center align-items-center mb-4 mt-4"
          >
            {{ xml_text }}
            <div class="col-md-12 text-center">
              <button class="btn btn-secondary" @click.prevent="selectFile2()">
                Exporta Xml +
              </button>
              <!-- <input type="file" class="form-control"> -->

              <input
                @change="handleFileChange"
                type="file"
                id="fileInput"
                style="display: none"
              />
              <!-- <button onclick="">Selecionar Arquivo</button> -->
            </div>
          </div>

          <!-- Dados gerais -->
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.produto-dados-gerais variant="light"
              >Dados gerais</b-button
            >
          </b-card-header>
          <b-collapse
            id="produto-dados-gerais"
            visible
            accordion="produto-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <div class="form-row form-group mb-4" role="group">
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="produto_id" class="col-form-label"
                      >Código</label
                    >
                    <div class="col">
                      <input
                        v-model="produto.produto_cod"
                        class="form-control text-right"
                        type="number"
                        placeholder="Gerado pelo sistema"
                        id="produto_id"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="produto_codbarras" class="col-form-label"
                      ><b-badge
                        data-name="credito"
                        class="field-status"
                        :variant="
                          produto.codbarras == null ? 'danger' : 'success'
                        "
                        >{{ "*Referencia" }}</b-badge
                      ></label
                    >
                    <div class="col">
                      <input
                        v-model="produto.codbarras"
                        class="form-control text-right"
                        type="text"
                        placeholder="Referência (Antigo código interno)"
                        id="produto_codbarras"
                        :class="{
                          'is-invalid':
                            submitted && $v.produto.codbarras.$error,
                        }"
                        ref="codbarras"
                      />
                      <div
                        v-if="submitted && $v.produto.codbarras.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.produto.codbarras.required">
                          {{ $t("mesages.validation.required") }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="idtipo" class="col-form-label"
                      ><b-badge
                        data-name="credito"
                        class="field-status"
                        :variant="
                          tipoProdutoSelecionado == null ? 'danger' : 'success'
                        "
                        >{{ "*Tipo" }}</b-badge
                      ></label
                    >
                    <div class="col">
                      <multiselect
                        v-model="tipoProdutoSelecionado"
                        :options="listTiposProduto"
                        placeholder="Selecione"
                        track-by="id"
                        deselect-label="Pressione ENTER para remover"
                        label="name"
                        @input="selectTipoProduto"
                      >
                        <template slot="singleLabel" slot-scope="{ option }"
                          ><strong>{{ option.name }}</strong></template
                        >
                      </multiselect>
                      <b-form-invalid-feedback
                        v-if="submitted && $v.produto.idtipo.$error"
                        :state="$v.produto.idtipo.required"
                      >
                        {{ $t("mesages.validation.selectone") }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div>
                    <h5 class="font-size-14 mb-4">
                      <b-badge
                        data-name="credito"
                        class="field-status"
                        :variant="
                          produto.produto_m_s == null ? 'danger' : 'success'
                        "
                        >{{ "*Classe" }}</b-badge
                      >
                    </h5>
                    <div class="form-check">
                      <input
                        v-model="produto.produto_m_s"
                        type="radio"
                        name="classe"
                        id="classe1"
                        value="P"
                        checked="checked"
                        class="form-check-input"
                      />
                      <label for="classe1" class="form-check-label"
                        >Produto</label
                      >
                    </div>
                    <div class="form-check">
                      <input
                        v-model="produto.produto_m_s"
                        type="radio"
                        name="classe"
                        id="classe-servico"
                        value="S"
                        class="form-check-input"
                      />
                      <label for="classe-servico" class="form-check-label"
                        >Serviço</label
                      >
                    </div>
                    <div class="form-check">
                      <input
                        v-model="produto.produto_m_s"
                        type="radio"
                        name="classe"
                        id="classe-mercadoria"
                        value="M"
                        class="form-check-input"
                      />
                      <label for="classe-mercadoria" class="form-check-label"
                        >Mercadoria</label
                      >
                    </div>
                    <b-form-invalid-feedback
                      v-if="submitted && $v.produto.produto_m_s.$error"
                      :state="$v.produto.produto_m_s.required"
                    >
                      {{ $t("mesages.validation.selectone") }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </div>
              <div
                id="gourp-produto-nome"
                class="form-row form-group mt-4"
                role="group"
              >
                <label for="produto-nome" class="col-sm-2 col-form-label"
                  ><b-badge
                    data-name="credito"
                    class="field-status"
                    :variant="
                      produto.produto_nome == null ? 'danger' : 'success'
                    "
                    >{{ "*Nome Produto" }}</b-badge
                  ></label
                >
                <div class="col">
                  <input
                    v-model="produto.produto_nome"
                    class="form-control"
                    type="text"
                    placeholder="Nome do Produto"
                    id="produto_nome"
                    maxlength="100"
                    :class="{
                      'is-invalid': submitted && $v.produto.produto_nome.$error,
                    }"
                  />
                  <div
                    v-if="submitted && $v.produto.produto_nome.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.produto.produto_nome.required">
                      {{ $t("mesages.validation.required") }}</span
                    >
                  </div>
                </div>
              </div>
              <div
                id="gourp-produto-descricao"
                class="form-row form-group"
                role="group"
              >
                <label for="produto-descricao" class="col-sm-2 col-form-label"
                  ><b-badge
                    data-name="credito"
                    class="field-status"
                    :variant="
                      produto.produto_descricao == null ? 'danger' : 'success'
                    "
                    >{{ "*Descrição Produto" }}</b-badge
                  ></label
                >
                <div class="col">
                  <input
                    v-model="produto.produto_descricao"
                    class="form-control"
                    type="text"
                    placeholder="Descrição do Produto"
                    id="produto_nome"
                    maxlength="100"
                    :class="{
                      'is-invalid':
                        submitted && $v.produto.produto_descricao.$error,
                    }"
                  />
                  <div
                    v-if="submitted && $v.produto.produto_descricao.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.produto.produto_descricao.required">
                      {{ $t("mesages.validation.required") }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="form-row form-group mt-4" role="group">
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="produto_produto_ncm" class="col-form-label">
                      <b-badge
                        data-name="credito"
                        class="field-status"
                        :variant="
                          produto.produto_ncm != null &&
                          produto.produto_ncm != ''
                            ? 'success'
                            : 'danger'
                        "
                        >{{ "*N. C. M." }}</b-badge
                      >
                    </label>
                    <div class="col">
                      <input
                        v-model="produto.produto_ncm"
                        class="form-control"
                        type="text"
                        maxlength="8"
                        placeholder="Digite o NCM"
                        id="produto_produto_ncm"
                        :class="{
                          'is-invalid':
                            submitted && $v.produto.produto_ncm.$error,
                        }"
                      />
                      <div
                        v-if="submitted && $v.produto.produto_ncm.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.produto.produto_ncm.required">
                          {{ $t("mesages.validation.required") }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="produto_cest" class="col-form-label"
                      ><b-badge
                        data-name="credito"
                        class="field-status"
                        :variant="
                          produto.produto_cest === null ? 'danger' : 'success'
                        "
                        >{{ "*CEST" }}</b-badge
                      >
                    </label>
                    <div class="col">
                      <input
                        v-model="produto.produto_cest"
                        class="form-control"
                        type="text"
                        placeholder="Digite o CEST"
                        id="produto_produto_cest"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="produto_preco_custo" class="col-form-label"
                      >Custo</label
                    >
                    <div class="col">
                      <!-- @input="formatInput_valor()" -->
                      <input
                        v-model="produto.preco_custo"
                        class="form-control text-right"
                        type="text"
                        placeholder="R$ 0,00"
                        id="produto_preco_custo"
                        :class="{
                          'is-invalid':
                            submitted && $v.produto.preco_custo.$error,
                        }"
                      />
                      <div
                        v-if="submitted && $v.produto.preco_custo.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.produto.preco_custo.required">
                          {{ $t("mesages.validation.required") }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="produto_margemlucro" class="col-form-label"
                      >Margem</label
                    >
                    <div class="col">
                      <input
                        @input="calcPreco"
                        v-model="produto.produto_margemlucro"
                        v-percent
                        class="form-control text-right"
                        type="text"
                        placeholder="0,00%"
                        id="produto_margemlucro"
                        :class="{
                          'is-invalid':
                            submitted && $v.produto.produto_margemlucro.$error,
                        }"
                        name="produto_margemlucro"
                      />
                      <div
                        v-if="
                          submitted && $v.produto.produto_margemlucro.$error
                        "
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.produto.produto_margemlucro.required">
                          {{ $t("mesages.validation.required") }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="produto_preco" class="col-form-label"
                      >Venda</label
                    >
                    <div class="col">
                      <input
                        v-model="preco_total"
                        class="form-control text-right"
                        type="text"
                        placeholder="R$ 0,00"
                        id="produto_preco"
                        :class="{
                          'is-invalid': submitted && $v.produto.preco.$error,
                        }"
                      />
                      <div
                        v-if="submitted && $v.produto.preco.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.produto.preco.required">
                          {{ $t("mesages.validation.required") }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="produto_preco_atacado" class="col-form-label"
                      >Atacado</label
                    >
                    <div class="col">
                      <!-- {{ produto.preco_atacado }} -->
                      <input
                        v-model="produto.preco_atacado"
                        @input="formatInput_valor_atacado()"
                        class="form-control text-right"
                        type="text"
                        placeholder="R$ 0,00"
                        id="produto_preco_atacado"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="produto_custo_medio" class="col-form-label"
                      >Custo médio</label
                    >
                    <div class="col">
                      <input
                        disabled
                        v-model="custo_medio"
                        class="form-control text-right"
                        type="text"
                        placeholder="R$ 0,00"
                        id="produto_custo_medio"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="produto_um" class="col-form-label"
                      ><b-badge
                        data-name="credito"
                        class="field-status"
                        :variant="
                          unidadeSelecionada == null ? 'danger' : 'success'
                        "
                        >{{ "*Unidade" }}</b-badge
                      ></label
                    >
                    <div class="col">
                      <multiselect
                        v-model="unidadeSelecionada"
                        :options="listUnidades"
                        placeholder="Selecione"
                        track-by="id"
                        deselect-label="Pressione ENTER para remover"
                        label="name"
                        @input="selectUnidade"
                      >
                        <template slot="singleLabel" slot-scope="{ option }"
                          ><strong>{{ option.name }}</strong></template
                        >
                      </multiselect>
                      <b-form-invalid-feedback
                        v-if="submitted && $v.produto.unidade_id.$error"
                        :state="$v.produto.unidade_id.required"
                      >
                        {{ $t("mesages.validation.selectone") }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="produto_qtd" class="col-form-label"
                      >Quantidade</label
                    >

                    <div class="col">
                      <input
                        v-model="produto.qtd"
                        min="1"
                        class="form-control text-right"
                        type="number"
                        placeholder="Digite"
                        id="produto_qtd"
                        :class="{
                          'is-invalid': submitted && $v.produto.qtd.$error,
                        }"
                      />
                      <div
                        v-if="submitted && $v.produto.qtd.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.produto.qtd.required">
                          {{ $t("mesages.validation.required") }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="produto_id" class="col-form-label"
                      ><b-badge
                        data-name="credito"
                        class="field-status"
                        :variant="produto.ativo != -1 ? 'danger' : 'success'"
                        >{{ "*Opcionais" }}</b-badge
                      ></label
                    >
                    <div class="col">
                      <b-form-checkbox
                        id="produto-ativo"
                        v-model="produto.ativo"
                        name="ativo"
                        value="-1"
                        unchecked-value="0"
                      >
                        Ativo
                      </b-form-checkbox>
                    </div>
                    <div class="col" v-if="produto.produto_produzido != -1">
                      <b-form-checkbox
                        id="produto-produto_controla_estoque"
                        v-model="produto.produto_controla_estoque"
                        name="produto_controla_estoque"
                        value="-1"
                        unchecked-value="0"
                        @change="abr_local_estoque($event)"
                      >
                        Controla estoque
                      </b-form-checkbox>
                    </div>
                    <div class="col">
                      <b-form-checkbox
                        id="produto-produto_balanca"
                        v-model="produto.produto_balanca"
                        name="produto_balanca"
                        value="-1"
                        unchecked-value="0"
                      >
                        Balança
                      </b-form-checkbox>
                    </div>
                    <div class="col">
                      <b-form-checkbox
                        id="produto-produto_produzido"
                        v-model="produto.produto_produzido"
                        name="produto_produzido"
                        value="-1"
                        unchecked-value="0"
                        @change="selecona_produzido($event)"
                      >
                        Produzido
                      </b-form-checkbox>
                    </div>
                    <div class="col">
                      <b-form-checkbox
                        id="produto-produto_materiaprima"
                        v-model="produto.produto_materiaprima"
                        name="produto_materiaprima"
                        value="-1"
                        unchecked-value="0"
                      >
                        Matéria prima
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-4">
                  <div class="form-group">
                    <label for="produto_idgrupo" class="col-form-label"
                      ><b-badge
                        data-name="credito"
                        class="field-status"
                        :variant="
                          grupoSelecionado == null ? 'danger' : 'success'
                        "
                        >{{ "*Grupo" }}</b-badge
                      ></label
                    >
                    <div class="col">
                      <multiselect
                        v-model="grupoSelecionado"
                        :options="listGrupos"
                        placeholder="Selecione"
                        track-by="id"
                        deselect-label="Pressione ENTER para remover"
                        label="name"
                        @input="selectGrupo"
                      >
                        <template slot="singleLabel" slot-scope="{ option }"
                          ><strong>{{ option.name }}</strong></template
                        >
                      </multiselect>
                      <b-form-invalid-feedback
                        v-if="submitted && $v.produto.grupo_id.$error"
                        :state="$v.produto.grupo_id.required"
                      >
                        {{ $t("mesages.validation.selectone") }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="produto_cean" class="col-form-label"
                      >GTIN (Cód. barras)</label
                    >
                    <div class="col">
                      <input
                        v-model="produto.produto_cean"
                        class="form-control text-right"
                        type="text"
                        placeholder="7899999999999"
                        id="produto_produto_cean"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="produto.produto_controla_estoque == -1">
                <div class="col-md-12">
                  <Label>Local Estoque</Label>
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    v-model="select_local_estoque_id"
                    @change="seleciona_local_estoque($event)"
                  >
                    <option value="-1">selecione o Local de Estoque</option>
                    <option
                      v-for="(item, index) in locais_estoques"
                      :key="index"
                      :value="item.id"
                    >
                      {{ item.descricao }}
                    </option>
                  </select>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="mb-1">
          <!-- Composições -->
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.produto-composicao variant="light"
              >Composição</b-button
            >
          </b-card-header>
          <b-collapse
            id="produto-composicao"
            accordion="produto-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card-text v-if="produto.produto_produzido == 0"
                >Para informações de compição do produto precisar marcar o campo
                <strong>PRODUZIDO</strong>.</b-card-text
              >
              <div v-if="produto.produto_produzido == -1" class="row mt-4">
                <div class="table-responsive mb-0">
                  <b-table
                    :items="produto.composicoes"
                    :fields="fieldsComposisoes"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    :hover="true"
                    :foot-clone="cloneFootComposicoes"
                  >
                    <template #cell(acoes)="row">
                      <b-dropdown
                        v-bind:id="'dropdown-' + row.item.id"
                        class="m-md-2"
                      >
                        <template #button-content>
                          <i data-v-6289eca4="" class="bx ri-menu-line"></i>
                        </template>
                        <b-dropdown-item @click="setDelete(row.item)"
                          ><i class="bx ri-eraser-fill"></i>
                          Excluir</b-dropdown-item
                        >
                      </b-dropdown>
                    </template>
                  </b-table>
                </div>
              </div>
              <div v-if="produto.produto_produzido == -1" class="row">
                <b-table-simple>
                  <b-thead id="b-head">
                    <b-tr>
                      <b-th class="b-th">Produto</b-th>
                      <b-th class="b-th">Quantidade</b-th>
                      <b-th class="b-th">Custo</b-th>
                      <b-th class="b-th">Venda</b-th>
                      <b-th>&nbsp;</b-th>
                      <b-th>&nbsp;</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr>
                      <b-td>
                        <multiselect
                          v-model="composicao.produto"
                          placeholder="Selecione"
                          label="produto_nome"
                          track-by="produto_nome"
                          :options="listProdutosCompor"
                          :option-height="104"
                          :custom-label="customLabelComposicao"
                          :show-labels="false"
                          deselect-label="ENTER para remover"
                          select-label="Pressione ENTER"
                          @select="compositorSelecionado($event)"
                        >
                          <template slot="singleLabel" slot-scope="props">
                            <span class="option__desc">
                              <span class="option__title"
                                >{{ props.option.codbarras }} -
                                {{ props.option.produto_nome }}</span
                              >
                            </span>
                          </template>
                          <template slot="option" slot-scope="props">
                            <div class="option__desc">
                              <span class="option__title"
                                >{{ props.option.codbarras }} -
                                {{ props.option.produto_nome }}</span
                              >
                            </div> </template
                          ><span slot="noResult"
                            >Opa! Nenhum registro encontrado</span
                          >
                        </multiselect>
                      </b-td>
                      <b-td style="max-width: 60px">
                        <!-- @input="
                            inputComposicaoQuantidade(
                              composicao.quantidadeFloat
                            )
                          " -->
                        <input
                          @input="
                            formatInputFloat($event, composicao, 'quantidade')
                          "
                          v-model="composicao.quantidade"
                          type="text"
                          class="form-control text-right"
                        />
                      </b-td>
                      <b-td style="max-width: 90px">
                        <!--  @input="inputComposicaoCusto(composicao.custoMoeda)" -->
                        <input
                          @input="formatInputFloat($event, composicao, 'custo')"
                          v-model="composicao.custo"
                          type="text"
                          class="form-control text-right"
                        />
                      </b-td>
                      <b-td style="max-width: 90px">
                        <!-- @input="inputComposicaoVenda(composicao.vendaMoeda)" -->
                        <input
                          @input="formatInputFloat($event, composicao, 'venda')"
                          v-model="composicao.venda"
                          type="text"
                          class="form-control text-right"
                        />
                      </b-td>
                      <b-td style="max-width: 90px">
                        <button
                          @click="enviar()"
                          class="btn btn-light"
                          type="button"
                        >
                          Adicionar
                        </button>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="mb-1">
          <!-- Integração Fornecedores -->
          <b-card-header header-tag="header" class="p-1 card-header" role="tab">
            <b-button
              block
              v-b-toggle.produto-integracao-fornecedores
              variant="light"
              >Integração Fornecedores
            </b-button>
          </b-card-header>
          <b-collapse
            id="produto-integracao-fornecedores"
            accordion="produto-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <div class="row mt-4">
                <div class="table-responsive mb-0">
                  <b-table
                    :items="produto.integracaoFornecedores"
                    :fields="fieldsIntegracaoFornecedor"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    :hover="true"
                    :foot-clone="cloneFootComposicoes"
                  >
                    <template #cell(acoes)="row">
                      <b-dropdown
                        v-bind:id="'dropdown-' + row.item.id"
                        class="m-md-2"
                      >
                        <template #button-content>
                          <i data-v-6289eca4="" class="bx ri-menu-line"></i>
                        </template>
                        <b-dropdown-item @click="setDelete(row.item)"
                          ><i class="bx ri-eraser-fill"></i>
                          Excluir</b-dropdown-item
                        >
                      </b-dropdown>
                    </template>
                  </b-table>
                </div>
              </div>
              <div class="row mt-4">
                <div class="table-responsive mb-0">
                  <b-table-simple>
                    <b-thead id="b-head">
                      <b-tr>
                        <b-th class="b-th">Fornecedor</b-th>
                        <b-th class="b-th">Código no Fornecedor</b-th>
                        <b-th class="b-th"
                          >Descrição no Produto (Conforme NF)</b-th
                        >
                        <b-th class="b-th">Fator</b-th>
                        <b-th class="b-th">Ações</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr>
                        <b-td>
                          <multiselect
                            v-model="integracaoFornecedor.fornecedor"
                            placeholder="Selecione"
                            label="pes_apelido"
                            track-by="pes_apelido"
                            :options="listFornecedores"
                            :option-height="104"
                            :custom-label="customLabelFornecedor"
                            :show-labels="false"
                            deselect-label="ENTER para remover"
                            select-label="Pressione ENTER"
                            @select="fornecedorSelecionado($event)"
                          >
                            <template slot="singleLabel" slot-scope="props">
                              <span class="option__desc">
                                <span class="option__title"
                                  >{{ props.option.id }} -
                                  {{ props.option.pes_apelido }}</span
                                >
                              </span>
                            </template>
                            <template slot="option" slot-scope="props">
                              <div class="option__desc">
                                <span class="option__title"
                                  >{{ props.option.id }} -
                                  {{ props.option.pes_apelido }}</span
                                >
                              </div>
                            </template>
                            <span slot="noResult"
                              >Opa! Nenhum registro encontrado</span
                            >
                          </multiselect>
                        </b-td>
                        <b-td>
                          <input
                            v-model="integracaoFornecedor.codigo"
                            type="text"
                            class="form-control text-left"
                          />
                        </b-td>
                        <b-td>
                          <input
                            v-model="integracaoFornecedor.descricao"
                            type="text"
                            class="form-control text-left"
                          />
                        </b-td>
                        <b-td>
                          <input
                            v-model="integracaoFornecedor.fator"
                            type="text"
                            class="form-control text-left"
                          />
                        </b-td>
                        <b-td>
                          <button
                            type="button"
                            class="btn btn-light"
                            @click="addFornecedorIntegracao"
                          >
                            Enviar
                          </button>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="mb-1">
          <!-- Impostos -->
          <b-card-header header-tag="header" class="p-1 card-header" role="tab">
            <b-button block v-b-toggle.produto-impostos variant="light"
              >Impostos</b-button
            >
          </b-card-header>
          <b-collapse
            id="produto-impostos"
            accordion="produto-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <div class="row mb-3">
                <div class="col-md-6">
                  <h6>Origem:</h6>
                  <multiselect
                    v-model="origSelecionado"
                    :options="listOrigem"
                    placeholder="Selecione"
                    object="false"
                    track-by="id"
                    deselect-label="Pressione ENTER para remover"
                    select-label="Pressione ENTER"
                    label="name"
                    @input="selectOrig"
                  >
                    <template slot="singlelabel" slot-scope="{ option }"
                      ><strong>{{ option.name }}</strong></template
                    >
                  </multiselect>
                  <b-form-invalid-feedback
                    v-if="submitted && $v.produto.produto_orig.$error"
                    :state="$v.produto.produto_orig.required"
                  >
                    {{ $t("mesages.validation.selectone") }}
                  </b-form-invalid-feedback>
                </div>
                <div class="col-md-3">
                  <h6>CFOP Padrão:</h6>
                  <multiselect
                    v-model="cfopSelecionado"
                    :options="listCFOP"
                    placeholder="Selecione"
                    object="false"
                    track-by="id"
                    desselect-label="Pressione ENTER para remover"
                    select-label="Pressione ENTER"
                    label="id"
                    @input="selectCFOP"
                  >
                    <template slot="singlelabel" slot-scope="{ option }">
                      <strong>{{ option.id }}-{{ option.name }}</strong>
                    </template>
                  </multiselect>
                  <b-form-invalid-feedback
                    v-if="submitted && $v.produto.produto_cfop.$error"
                    :state="$v.produto.produto_cfop.required"
                  >
                    {{ $t("mesages.validation.selectone") }}
                  </b-form-invalid-feedback>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <b-tabs
                    pills
                    vertical
                    nav-class="p-0"
                    nav-wrapper-class="col-sm-3"
                    content-class="pt-0 px-2 text-muted"
                  >
                    <b-tab title="Saídas" active title-item-class="mb-2">
                      <b-card-body>
                        <h4 class="card-title mb-4">ICMS Saídas:</h4>
                        <div class="row">
                          <div class="col-md-4">
                            <h6>
                              {{
                                this.currentEmpresa.crt == 1 ? "CSOSN" : "CST"
                              }}:
                            </h6>
                          </div>
                          <div class="col-md-2">
                            <h6>Alíquota:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>% Base Calc.:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>Alíquota ST:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>% Base Calc. ST:</h6>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <!-- {{'esse'}} -->
                            <!-- CSTsFilter - estave me options -->
                            <multiselect
                              v-model="CSTICMSSelecionado"
                              :options="cson_vet"
                              placeholder="Selecione"
                              object="false"
                              track-by="id"
                              desselect-label="Pressione ENTER"
                              label="name"
                              @select="selectCST($event)"
                            >
                              <template
                                slot="singlelabel"
                                slot-scope="{ option }"
                                ><strong>{{ option.name }}</strong></template
                              >
                            </multiselect>
                            <b-form-invalid-feedback
                              v-if="submitted && $v.produto.produto_cst.$error"
                              :state="$v.produto.produto_cst.required"
                            >
                              {{ $t("mesages.validation.selectone") }}
                            </b-form-invalid-feedback>
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_picms"
                              class="form-control text-right"
                              type="text"
                              placeholder="% ICMS"
                              id="produto_produto_picms"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pbc_icms"
                              class="form-control text-right"
                              type="text"
                              placeholder="% Base Cálculo"
                              id="produto_produto_pbc_icms"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_picmsst"
                              class="form-control text-right"
                              type="text"
                              placeholder="% ICMS ST"
                              id="produto_produto_picmsst"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pbc_icmsst"
                              class="form-control text-right"
                              type="text"
                              placeholder="% Base Cálculo ST"
                              id="produto_produto_pbc_icmsst"
                            />
                          </div>
                        </div>
                      </b-card-body>
                      <b-card-body>
                        <h4 class="card-title mb-4">IPI Saídas:</h4>
                        <div class="row">
                          <div class="col-md-4">
                            <h6>CST:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>Cód. Enquad.:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>Alíquota:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>% Base Calc.:</h6>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <multiselect
                              v-model="CSTIPISelecionado"
                              :options="listCSTsIPI"
                              placeholder="Selecione"
                              object="false"
                              track-by="id"
                              desselect-label="Pressione ENTER"
                              label="name"
                              @input="selectCSTIPI"
                            >
                              <template
                                slot="singlelabel"
                                slot-scope="{ option }"
                                ><strong>{{ option.name }}</strong></template
                              >
                            </multiselect>
                            <b-form-invalid-feedback
                              v-if="
                                submitted && $v.produto.produto_cstipi.$error
                              "
                              :state="$v.produto.produto_cstipi.required"
                            >
                              {{ $t("mesages.validation.selectone") }}
                            </b-form-invalid-feedback>
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_cenq"
                              class="form-control text-right"
                              type="text"
                              placeholder="Cód. Enquandramento"
                              id="produto_produto_cenq"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pipi"
                              class="form-control text-right"
                              type="text"
                              placeholder="% IPI"
                              id="produto_produto_pipi"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pbc_ipi"
                              class="form-control text-right"
                              type="text"
                              placeholder="% Base Cálculo"
                              id="produto_produto_pbc_ipi"
                            />
                          </div>
                        </div>
                      </b-card-body>
                      <b-card-body>
                        <h4 class="card-title mb-4">PIS Saídas:</h4>
                        <div class="row">
                          <div class="col-md-4">
                            <h6>CST:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>Alíquota:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>% Base Calc.:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>Alíquota ST:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>% Base Calc. ST:</h6>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <multiselect
                              v-model="CSTPISSelecionado"
                              :options="listCSTsPIS"
                              placeholder="Selecione"
                              object="false"
                              track-by="id"
                              desselect-label="Pressione ENTER"
                              label="name"
                              @input="selectCSTPIS"
                              class="produto_cstpis"
                            >
                              <template
                                slot="singlelabel"
                                slot-scope="{ option }"
                                ><strong>{{ option.name }}</strong></template
                              >
                            </multiselect>
                            <b-form-invalid-feedback
                              v-if="
                                submitted && $v.produto.produto_cstpis.$error
                              "
                              :state="$v.produto.produto_cstpis.required"
                            >
                              {{ $t("mesages.validation.selectone") }}
                            </b-form-invalid-feedback>
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_ppis"
                              class="form-control text-right"
                              type="text"
                              placeholder="% PIS"
                              id="produto_produto_ppis"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pbc_pis"
                              class="form-control text-right"
                              type="text"
                              placeholder="% Base Cálculo"
                              id="produto_produto_pbc_pis"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_ppisst"
                              class="form-control text-right"
                              type="text"
                              placeholder="% PIS ST"
                              id="produto_produto_ppisst"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pBC_pisst"
                              class="form-control text-right"
                              type="text"
                              placeholder="% Base Cálculo ST"
                              id="produto_produto_pBC_pisst"
                            />
                          </div>
                        </div>
                      </b-card-body>
                      <b-card-body>
                        <h4 class="card-title mb-4">COFINS Saídas:</h4>
                        <div class="row">
                          <div class="col-md-4">
                            <h6>CST:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>Alíquota:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>% Base Calc.:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>Alíquota ST:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>% Base Calc. ST:</h6>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <multiselect
                              v-model="CSTCOFINSSelecionado"
                              :options="listCSTsCOFINS"
                              placeholder="Selecione"
                              object="false"
                              track-by="id"
                              desselect-label="Pressione ENTER"
                              label="name"
                              @input="selectCSTCOFINS"
                              class="produto_cstcofins"
                            >
                              <template
                                slot="singlelabel"
                                slot-scope="{ option }"
                                ><strong>{{ option.name }}</strong></template
                              >
                            </multiselect>
                            <b-form-invalid-feedback
                              v-if="
                                submitted && $v.produto.produto_cstcofins.$error
                              "
                              :state="$v.produto.produto_cstcofins.required"
                            >
                              {{ $t("mesages.validation.selectone") }}
                            </b-form-invalid-feedback>
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pcofins"
                              class="form-control text-right"
                              type="text"
                              placeholder="% COFINS"
                              id="produto_produto_pcofins"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pbc_cofins"
                              class="form-control text-right"
                              type="text"
                              placeholder="% Base Cálculo"
                              id="produto_produto_pbc_cofins"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pcofinsst"
                              class="form-control text-right"
                              type="text"
                              placeholder="% COFINS ST"
                              id="produto_produto_pcofinsst"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pbc_cofinsst"
                              class="form-control text-right"
                              type="text"
                              placeholder="% Base Cálculo ST"
                              id="produto_produto_pbc_cofinsst"
                            />
                          </div>
                        </div>
                      </b-card-body>
                    </b-tab>
                    <b-tab title="Entradas" title-item-class="mb-2">
                      <b-card-body>
                        <h4 class="card-title mb-4">ICMS Entradas:</h4>
                        <div class="row">
                          <div class="col-md-4">
                            <h6>CST/CSOSN</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>Alíquota:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>% Base Calc.:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>Alíquota ST:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>% Base Calc. ST:</h6>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <multiselect
                              v-model="CSTICMSeSelecionado"
                              :options="listCSTsICMS"
                              placeholder="Selecione"
                              object="false"
                              track-by="id"
                              desselect-label="Pressione ENTER"
                              label="name"
                              @input="selectCSTe"
                            >
                              <template
                                slot="singlelabel"
                                slot-scope="{ option }"
                                ><strong>{{ option.name }}</strong></template
                              >
                            </multiselect>
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_picmse"
                              class="form-control text-right"
                              type="text"
                              placeholder="% ICMS"
                              id="produto_produto_picmse"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pbc_icmse"
                              class="form-control text-right"
                              type="text"
                              placeholder="% Base Cálculo"
                              id="produto_produto_pbc_icmse"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_picmsste"
                              class="form-control text-right"
                              type="text"
                              placeholder="% ICMS ST"
                              id="produto_produto_picmsste"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pbc_icmsste"
                              class="form-control text-right"
                              type="text"
                              placeholder="% Base Cálculo ST"
                              id="produto_produto_pbc_icmsste"
                            />
                          </div>
                        </div>
                      </b-card-body>
                      <b-card-body>
                        <h4 class="card-title mb-4">IPI Entradas:</h4>
                        <div class="row">
                          <div class="col-md-4">
                            <h6>CST:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>Cód. Enquad.:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>Alíquota:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>% Base Calc.:</h6>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <multiselect
                              v-model="CSTIPIeSelecionado"
                              :options="listCSTsIPI"
                              placeholder="Selecione"
                              object="false"
                              track-by="id"
                              desselect-label="Pressione ENTER"
                              label="name"
                              @input="selectCSTIPIe"
                            >
                              <template
                                slot="singlelabel"
                                slot-scope="{ option }"
                                ><strong>{{ option.name }}</strong></template
                              >
                            </multiselect>
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_cenqe"
                              class="form-control text-right"
                              type="text"
                              placeholder="Cód. Enquandramento"
                              id="produto_produto_cenqe"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pipie"
                              class="form-control text-right"
                              type="text"
                              placeholder="% IPI"
                              id="produto_produto_pipie"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pbc_ipie"
                              class="form-control text-right"
                              type="text"
                              placeholder="% Base Cálculo"
                              id="produto_produto_pbc_ipie"
                            />
                          </div>
                        </div>
                      </b-card-body>
                      <b-card-body>
                        <h4 class="card-title mb-4">PIS Entradas:</h4>
                        <div class="row">
                          <div class="col-md-4">
                            <h6>CST:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>Alíquota:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>% Base Calc.:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>Alíquota ST:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>% Base Calc. ST:</h6>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <multiselect
                              v-model="CSTPISeSelecionado"
                              :options="listCSTsPIS"
                              placeholder="Selecione"
                              object="false"
                              track-by="id"
                              desselect-label="Pressione ENTER"
                              label="name"
                              @input="selectCSTPISe"
                            >
                              <template
                                slot="singlelabel"
                                slot-scope="{ option }"
                                ><strong>{{ option.name }}</strong></template
                              >
                            </multiselect>
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_ppise"
                              class="form-control text-right"
                              type="text"
                              placeholder="% PIS"
                              id="produto_produto_ppise"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pbc_pise"
                              class="form-control text-right"
                              type="text"
                              placeholder="% Base Cálculo"
                              id="produto_produto_pbc_pise"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_ppisste"
                              class="form-control text-right"
                              type="text"
                              placeholder="% PIS ST"
                              id="produto_produto_ppisste"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pBC_pisste"
                              class="form-control text-right"
                              type="text"
                              placeholder="% Base Cálculo ST"
                              id="produto_produto_pBC_pisste"
                            />
                          </div>
                        </div>
                      </b-card-body>
                      <b-card-body>
                        <h4 class="card-title mb-4">COFINS Entradas:</h4>
                        <div class="row">
                          <div class="col-md-4">
                            <h6>CST:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>Alíquota:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>% Base Calc.:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>Alíquota ST:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>% Base Calc. ST:</h6>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <multiselect
                              v-model="CSTCOFINSeSelecionado"
                              :options="listCSTsCOFINS"
                              placeholder="Selecione"
                              object="false"
                              track-by="id"
                              desselect-label="Pressione ENTER"
                              label="name"
                              @input="selectCSTCOFINSe"
                            >
                              <template
                                slot="singlelabel"
                                slot-scope="{ option }"
                                ><strong>{{ option.name }}</strong></template
                              >
                            </multiselect>
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pcofinse"
                              class="form-control text-right"
                              type="text"
                              placeholder="% COFINS"
                              id="produto_produto_pcofinse"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pbc_cofinse"
                              class="form-control text-right"
                              type="text"
                              placeholder="% Base Cálculo"
                              id="produto_produto_pbc_cofinse"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pcofinsste"
                              class="form-control text-right"
                              type="text"
                              placeholder="% COFINS ST"
                              id="produto_produto_pcofinsste"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pbc_cofinsste"
                              class="form-control text-right"
                              type="text"
                              placeholder="% Base Cálculo ST"
                              id="produto_produto_pbc_cofinsste"
                            />
                          </div>
                        </div>
                      </b-card-body>
                    </b-tab>
                    <b-tab title="Tabela ICMS" title-item-class="mb-2">
                      <!-- <b-card-text>{{ text }}</b-card-text> -->
                      <div class="row">
                        <div class="col-md-12">
                          <b-table
                            bordered
                            hover
                            :items="tabs_icms"
                            id="doc-itens"
                            :fields="fields_icms"
                          >
                            <template #cell(uf)="row">
                              <div>
                                <input
                                  v-model="row.item.uf"
                                  type="text"
                                  class="form-control text-left"
                                  placeholder="Digite"
                                />
                              </div>
                            </template>
                            <template #cell(icms)="row">
                              <div>
                                <input
                                  v-model="row.item.icms"
                                  type="text"
                                  class="form-control text-left"
                                  placeholder="Digite"
                                />
                              </div>
                            </template>
                            <template #cell(red_base)="row">
                              <div>
                                <input
                                  v-model="row.item.red_base"
                                  type="text"
                                  class="form-control text-left"
                                  placeholder="Digite"
                                  @input="
                                    formatInputFloat(
                                      $event,
                                      row.item,
                                      'red_base'
                                    )
                                  "
                                />
                              </div>
                            </template>
                            <template #cell(margem)="row">
                              <div>
                                <input
                                  v-model="row.item.margem"
                                  type="text"
                                  class="form-control text-left"
                                  placeholder="Digite"
                                  @input="
                                    formatInputFloat($event, row.item, 'margem')
                                  "
                                />
                              </div>
                            </template>
                            <template #cell(diferimento)="row">
                              <div>
                                <input
                                  v-model="row.item.diferimento"
                                  type="text"
                                  class="form-control text-left"
                                  placeholder="Digite"
                                  @input="
                                    formatInputFloat(
                                      $event,
                                      row.item,
                                      'diferimento'
                                    )
                                  "
                                />
                              </div>
                            </template>
                            <template #cell(excluir)="row">
                              <div>
                                <button
                                  class="btn btn-danger"
                                  @click.prevent="dlt_icms(row.item, row.index)"
                                >
                                  Excluir
                                </button>
                              </div>
                            </template>
                          </b-table>
                        </div>
                      </div>
                      <div
                        class="row d-flex justify-content-center align-items-center"
                      >
                        <div class="col-md-12 text-center">
                          <button
                            class="btn btn-success"
                            @click.prevent="add_icms()"
                          >
                            adicionar
                          </button>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab title="Tabela ICMS CST" title-item-class="mb-2">
                      <b-card-text>{{ text }}</b-card-text>
                    </b-tab>
                    <b-tab title="Tabela PIS CST" title-item-class="mb-2">
                      <b-card-text>{{ text }}</b-card-text>
                    </b-tab>
                    <b-tab title="Tabela COFINS CST" title-item-class="mb-2">
                      <b-card-text>{{ text }}</b-card-text>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="mb-1">
          <!-- Outras informações -->
          <b-card-header header-tag="header" class="p-1 card-header" role="tab">
            <b-button block v-b-toggle.produto-outros variant="light"
              >Outras</b-button
            >
          </b-card-header>
          <b-collapse
            id="produto-outros"
            accordion="produto-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card-body>
                <h4 class="card-title mb-12">SPED:</h4>
                <div class="row">
                  <div class="col-md-12">
                    <h6>Classificação:</h6>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <multiselect
                      v-model="classificacaoSPEDSelecionado"
                      :options="listClassificacaoSPED"
                      placeholder="Selecione"
                      object="false"
                      track-by="id"
                      desselect-label="Pressione ENTER"
                      label="name"
                      @input="selectClassificacaoSPED"
                    >
                      <template slot="singlelabel" slot-scope="{ option }"
                        ><strong>{{
                          option.id + "-" + option.name
                        }}</strong></template
                      >
                    </multiselect>
                    <b-form-invalid-feedback
                      v-if="submitted && $v.produto.produto_class_sped.$error"
                      :state="$v.produto.produto_class_sped.required"
                    >
                      {{ $t("mesages.validation.selectone") }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </b-card-body>
              <b-card-body>
                <div class="row">
                  <div class="col-md-4">
                    <h6>Peso líq.:</h6>
                  </div>
                  <div class="col-md-4">
                    <h6>Peso bruto:</h6>
                  </div>
                  <div class="col-md-4">
                    <h6>Fator exportação:</h6>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <input
                      v-model="produto.produto_pesoliq"
                      class="form-control text-right"
                      type="text"
                      placeholder="Em gramas"
                      id="produto_produto_pesoliq"
                      :class="{
                        'is-invalid':
                          submitted && $v.produto.produto_pesoliq.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.produto.produto_pesoliq.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.produto.produto_pesoliq.required">
                        {{ $t("mesages.validation.required") }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-4">
                    <input
                      v-model="produto.produto_pesobruto"
                      class="form-control text-right"
                      type="text"
                      placeholder="Em gramas"
                      id="produto_produto_pesobruto"
                      :class="{
                        'is-invalid':
                          submitted && $v.produto.produto_pesobruto.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.produto.produto_pesobruto.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.produto.produto_pesobruto.required">
                        {{ $t("mesages.validation.required") }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-4">
                    <input
                      v-model="produto.produto_fatexport"
                      class="form-control text-right"
                      type="text"
                      placeholder="Multiplicador"
                      id="produto_produto_fatexport"
                      :class="{
                        'is-invalid':
                          submitted && $v.produto.produto_fatexport.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.produto.produto_fatexport.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.produto.produto_fatexport.required">
                        {{ $t("mesages.validation.required") }}</span
                      >
                    </div>
                  </div>
                </div>
              </b-card-body>
              <b-card-body>
                <div class="row">
                  <div class="col-md-4">
                    <h6>Cód. Integração:</h6>
                  </div>
                  <div class="col-md-4">
                    <h6>Conta Contábil:</h6>
                  </div>
                  <div class="col-md-4">
                    <h6>Integr. Domínio:</h6>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <input
                      v-model="produto.produto_cod_int"
                      class="form-control text-right"
                      type="text"
                      placeholder="Código do sistema integrado"
                      id="produto_produto_cod_int"
                    />
                  </div>
                  <div class="col-md-4">
                    <input
                      v-model="produto.produto_cta"
                      class="form-control text-right"
                      type="text"
                      placeholder="Código da conta contábil"
                      id="produto_produto_cta"
                    />
                  </div>
                  <div class="col-md-4">
                    <input
                      v-model="produto.produto_intdominio"
                      class="form-control text-right"
                      type="text"
                      placeholder="Multiplicador"
                      id="produto_produto_intdominio"
                    />
                  </div>
                </div>
              </b-card-body>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="mb-1">
          <!-- Tabelas de Preço -->
          <b-card-header header-tag="header" class="p-1 card-header" role="tab">
            <b-button block v-b-toggle.produto-tabelas-preco variant="light"
              >Tabelas de Preço</b-button
            >
          </b-card-header>
          <b-collapse
            id="produto-tabelas-preco"
            accordion="produto-accordion"
            role="tabpanel"
          >
            <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Tabelas de preço</h4>
                    <p class="card-title-desc">
                      Informe os preços do Produto conforme tabela desejada.
                    </p>
                    <div class="table-responsive">
                      <table class="table mb-0">
                        <thead>
                          <tr>
                            <th
                              v-for="(item, index) in listTabelasPreco"
                              :key="index"
                              class="th"
                            >
                              {{ item.ttp_nome }}
                            </th>
                            <th class="th text-center">#</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="table-light"
                            v-for="(tabela, index) in produto.tabelaPrecos"
                            :key="index"
                          >
                            <td
                              v-for="(item, index) in listTabelasPreco"
                              :key="index"
                              class="td"
                            >
                              {{ tabela[item.ttp_nome] }}
                            </td>
                            <td class="td">
                              <button
                                @click="delTabelaPreco(tabela)"
                                type="button"
                                class="btn btn-outline-success"
                              >
                                Remover
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- v-if="produto.tabelaPrecos.length <= 0" -->
            <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table mb-0">
                        <thead>
                          <tr>
                            <th
                              v-for="(item, index) in listTabelasPreco"
                              :key="index"
                              class="th"
                            >
                              {{ item.ttp_nome }}
                            </th>
                            <th class="th text-center">#</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              v-for="(item, index) in listTabelasPreco"
                              :key="index"
                              class="b-td min-field"
                            >
                              <input
                                :name="item.ttp_nome"
                                class="form-control text-right min-field"
                                v-model="tabelaPreco[item.ttp_nome + 'Moeda']"
                                @input="
                                  inputTabelaPreco(
                                    tabelaPreco[item.ttp_nome + 'Moeda'],
                                    item.ttp_nome
                                  )
                                "
                              />
                            </td>
                            <td class="td">
                              <button
                                @click="addTabelaPreco()"
                                type="button"
                                class="btn btn-outline-success"
                              >
                                Adicionar
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-collapse>
        </b-card>
      </form>
    </div>

    <div class="card-body">
      <div class="col-md-12 mt-10">
        <!-- formSubmit($event) -->
        <button class="btn btn-light" @click.prevent="setPost()">Gravar</button>
      </div>
    </div>
  </div>
</template>

<style>
.min-field {
  min-width: 70px !important;
}
</style>
