<script>
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import { listOrigens } from "@/components/defaults/origens";
import { listCFOPs } from "@/components/defaults/cfops";
import { http } from "../../../helpers/easyindustriaapi/config";
import modelComposicao from "@/model/composicao.js";
//import { http } from "../../../helpers/easyindustriaapi/config";
console.clear();

export default {
  page: {
    title: "Alterar Produto",
    meta: [{ name: "description", content: appConfig.description }],
  },
  props: {
    currentEmpresa: { type: Object },
    oldProduto: { type: Object },
    listTabelas: { type: Object },
    listImpostos: { type: Object },
    vet_uf_icms: { type: Array },
    vet_loc_estoques: { type: Array },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      select_local_estoque_id:null,
      select_un_compo: null,
      vet_uf_icms_new_objeto: [],
      array_all_icms_ufs: [],
      tabs_icms: [],
      fields_icms: [
        // {
        //   label: "Código",
        //   key: "id",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        {
          label: "Uf",
          key: "uf",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "%ICMS",
          key: "icms",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "%Red base Calc",
          key: "red_base",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Margem",
          key: "margem",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Diferimento",
          key: "diferimento",
          tdClass: "text-center",
          thClass: "text-center",
        },
        // {
        //   label: "Vlr Bruto",
        //   key: "bruto",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        // {
        //   label: "Desconto",
        //   key: "desconto",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },

        // {
        //   label: "Ativo",
        //   key: "ativo",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        // { label: "Número", key: "numero", tdClass: 'text-center', thClass: 'text-center' },
        // { label: "Série", key: "serie", tdClass: 'text-center', thClass: 'text-center', formatter: "formatterCurrBR" },
        // { label: "Criado", key: "data_criacao", tdClass: 'text-right', thClass: 'text-center' },
        {
          // label: "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0Ações\u00A0\u00A0\u00A0",
          label: "Acões",
          key: "excluir",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      fieldsComposisoes: [
        // {
        //   label: "Código",
        //   key: "id",
        //   sortable: true,
        //   tdClass: "text-right",
        //   thClass: "text-center",
        // },
        // {
        //   label: "Código Produto",
        //   key: "id",
        //   sortable: true,
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        {
          label: "Nome do Produto",
          key: "produto_nome",
          sortable: true,
          thClass: "text-center",
        },
        {
          label: "Unidade",
          key: "un",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Custo R$",
          key: "custo",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
          formatter: "formatterCurrBR",
        },
        {
          label: "Preço R$",
          key: "venda",
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
          formatter: "formatterCurrBR",
        },
        {
          label: "Quantidade",
          key: "quantidade",
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Ações",
          key: "acoes",
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      preco_total: null,
      submitted: false,
      text: "Necessário gravar o produto antes de informar a composição",
      loader: {
        get: false,
      },
      editando: false,
      produto: {
        tabela_preco: {},
      },
      custo_medio: null,
      grupoSelecionado: null,
      unidadeSelecionada: null,
      tipoProdutoSelecionado: null,
      origSelecionado: null,
      cfopSelecionado: null,
      CSTICMSSelecionado: null,
      CSTIPISelecionado: null,
      CSTPISSelecionado: null,
      CSTCOFINSSelecionado: null,
      CSTICMSeSelecionado: null,
      CSTIPIeSelecionado: null,
      CSTPISeSelecionado: null,
      CSTCOFINSeSelecionado: null,
      classificacaoSPEDSelecionado: null,
      listOrigem: [],
      listCFOP: [],
      listGrupos: [],
      listUnidades: [],
      listTiposProduto: [],
      listClassificacaoSPED: [],
      listCSTsICMS: [],
      listCSTsIPI: [],
      listCSTsPIS: [],
      listCSTsCOFINS: [],
      tipos_tabelas: [],
      tabelas: [],
      objetos: [],
      valoresInputs: {},
      inputsPreenchidos: [],
      inputsPreenchidosAnteriores: "",
      all_inputs: {},
      composicaoIDX: -1,
      composicao: {},
      list_composicao: [],
      // pega todos os fornecedores de produto;
      list_fornecedores_integration: [],
      // objeto que usei para não da erro nas v-models
      // mas não vai precisar mais;
      fornecedores_integration: {},
      comp_prod_vet: [],
      locais_estoques:[]
    };
  },
  validations() {
    return {
      produto: {
        codbarras: { required },
        idtipo: { required },
        produto_m_s: { required },
        produto_nome: { required },
        produto_descricao: { required },
        produto_ncm: { required },
        preco_custo: { required },
        preco: { required },
        produto_margemlucro: { required },
        unidade_id: { required },
        qtd: { required },
        grupo_id: { required },
        produto_orig: { required },
        produto_cst: { required },
        produto_cstpis: { required },
        produto_pbc_pis: { required },
        produto_ppis: { required },
        produto_cstcofins: { required },
        produto_pbc_cofins: { required },
        produto_pcofins: { required },
        produto_cstipi: { required },
        produto_pbc_ipi: { required },
        produto_pipi: { required },
        produto_cfop: { required },
        produto_class_sped: { required },
        produto_pesoliq: { required },
        produto_pesobruto: { required },
        produto_fatexport: { required },
      },
    };
  },
  created() {
    this.array_all_icms_ufs = this.vet_uf_icms;
    this.tabs_icms = this.oldProduto.tabela_icms_ufs;
    this.comp_prod_vet = JSON.parse(
      JSON.stringify(this.oldProduto.compoe_produtos)
    );
    this.locais_estoques = this.vet_loc_estoques
  },
  mounted() {
    this.getTipoTabela();
    this.produto = this.oldProduto;
    //this.verifica_se_tem_composição();
    console.log("esstuo no mounted");
    console.log(this.produto.compoe_produtos);
    this.formata_custo();
    this.calcPreco();
    this.listOrigem = listOrigens;
    this.listCFOP = listCFOPs;
    var grupos = this.listTabelas.grupos;
    this.composicao = new modelComposicao(this.composicaoIDX, this.produto.id);

    this.listGrupos = grupos.map((item) => {
      return {
        name: item.descricaogrupo,
        id: item.id,
      };
    });
    var unidades = this.listTabelas.unidades;
    this.listUnidades = unidades.map((item) => {
      return {
        name: item.DescricaoUn,
        id: item.id,
      };
    });
    var tiposProduto = this.listTabelas.tiposProduto;
    this.listTiposProduto = tiposProduto.map((item) => {
      return {
        name: item.descricao,
        id: item.id,
      };
    });
    var classificacaoSPED = this.listTabelas.classificacaoSPED;
    this.listClassificacaoSPED = classificacaoSPED.map((item) => {
      return {
        name: item.clasped_descricao,
        id: item.id,
      };
    });
    var CSTsICMS = this.listImpostos.icms;
    this.listCSTsICMS = CSTsICMS.map((item) => {
      return {
        name: item.descricao,
        id: parseInt(item.cst),
      };
    });
    var CSTsIPI = this.listImpostos.ipi;
    this.listCSTsIPI = CSTsIPI.map((item) => {
      return {
        name: item.descricao,
        id: item.cst,
      };
    });
    var CSTsPIS = this.listImpostos.pis;
    this.listCSTsPIS = CSTsPIS.map((item) => {
      return {
        name: item.descricao,
        id: item.cst,
      };
    });
    var CSTsCOFINS = this.listImpostos.cofins;
    this.listCSTsCOFINS = CSTsCOFINS.map((item) => {
      return {
        name: item.descricao,
        id: item.cst,
      };
    });
    this.setValues();
    this.setNewTitle();
    this.$refs.codbarras.focus();
    // rever essa logica aqui
    // this.get_composicao_produto();
    // this.get_fornecedor_integrar();
    if (this.tabs_icms.length == 0) {
      this.new_vet_uf_icms();
    }
  },
  computed: {
    CSTsFilter() {
      return this.listCSTsICMS.filter((valor) => {
        return this.currentEmpresa.crt === 1 ? valor.id >= 100 : valor.id < 100;
      });
    },
  },
  directives: {
    percent: {
      updated(el) {
        el.value = el.value + "%";
      },
    },
  },
  methods: {
    adc_mt_prima(){
      
      this.produto.loc_estoque = this.select_local_estoque_id
      console.log(this.produto)
       this.doPostAddEstoqueMT(this.produto)
    },
    async doPostAddEstoqueMT(produto) {
      console.log(produto);
      // this.onLoader();
      // this.changeValues(produto, ",", ".");

      console.log("dentro do update");

      try {
        let response = await http.post(
          "/produto/addEstoquemt?empresa_id=" + this.currentEmpresa.id,
          produto
        );
        //console.log(response);

        if (response.status === 200) {
          console.log("entrou aqui");
          // this.getData();
          //this.back();
          // setTimeout(() => {
          //   this.offLoader();
          // }, 1000);
          this.makeToast("success", "Registro alterado");
        }
      } catch (error) {
        this.makeToast("danger", error.response);
      }
    },
    seleciona_local_estoque(event){
      console.log(event)
    },
    seleciona_estoqe_comp_edit(event){
      console.log(event.target.value)
    },
    addestoque(objeto) {
      console.log("Aumentadondo estoque");
      console.log(objeto);
      console.log(this.comp_prod_vet);

      let composicao = this.comp_prod_vet.find((c) => c.id == objeto.id);
      console.log("mostrando composi~çao com valro antigo");
      console.log(composicao);
      objeto.old_valor = composicao.quantidade;
      objeto.loc_esoque = this.produto.loc_esoque;
      objeto.produto_produzido = this.produto.produto_produzido;
      console.log("mosntrando Obeto final");
      console.log(objeto);
      this.doPostAddEstoque(objeto);
    },
    seleciona_unidade_comp_edit(event, nome) {
      console.log(event.target.value);
      console.log(nome);
    },
    alteraComposicao(objeto) {
      console.log("mostrnado Obejto COmposição");
      console.log(objeto);
      objeto.custo = this.new_convert_srting_float(objeto.custo);
      objeto.venda = this.new_convert_srting_float(objeto.venda);

      this.doPut(objeto);
    },
    async doPut(produto) {
      console.log(produto);
      // this.onLoader();
      // this.changeValues(produto, ",", ".");

      console.log("dentro do update");

      try {
        let response = await http.put(
          "/prodcomp/" + produto.id + "?empresa_id=" + this.currentEmpresa.id,
          produto
        );
        //console.log(response);

        if (response.status === 200) {
          console.log("entrou aqui");
          // this.getData();
          //this.back();
          // setTimeout(() => {
          //   this.offLoader();
          // }, 1000);
          this.makeToast("success", "Registro alterado");
        }
      } catch (error) {
        this.makeToast("danger", error.response);
      }
    },
    async doPostAddEstoque(produto) {
      console.log(produto);
      // this.onLoader();
      // this.changeValues(produto, ",", ".");

      console.log("dentro do update");

      try {
        let response = await http.post(
          "/produto/addEstoque?empresa_id=" + this.currentEmpresa.id,
          produto
        );
        //console.log(response);

        if (response.status === 200) {
          console.log("entrou aqui");
          // this.getData();
          //this.back();
          // setTimeout(() => {
          //   this.offLoader();
          // }, 1000);
          this.makeToast("success", "Registro alterado");
        }
      } catch (error) {
        this.makeToast("danger", error.response);
      }
    },
    async edit_icms(objeto, index) {
      console.log("estou no Editar tabela ICMS");
      let margem = this.new_convert_srting_float(objeto.margem);
      let diferimento = this.new_convert_srting_float(objeto.diferimento);
      let red_base = this.new_convert_srting_float(objeto.red_base);
      objeto.margem = margem;
      objeto.diferimento = diferimento;
      objeto.red_base = red_base;
      console.log(margem);
      console.log(diferimento);
      console.log(red_base);
      console.log(objeto);
      console.log(index);
      try {
        let response = await http.put(
          "/produto/tabela/" +
            objeto.id +
            "?empresa_id=" +
            this.currentEmpresa.id,
          objeto
        );
        //console.log(response);

        if (response.status === 200) {
          console.log("entrou aqui");
          //this.getData();
          //this.back();
          setTimeout(() => {
            this.offLoader();
          }, 1000);
          this.makeToast("success", "Registro alterado");
        }
      } catch (error) {
        this.makeToast("danger", error.response);
      }
    },
    new_vet_uf_icms() {
      console.log("mostrando todos os impostos de ufs no insert");
      console.log(this.array_all_icms_ufs);
      console.log(this.currentEmpresa.uf);
      let selecionado_uf_icms = this.array_all_icms_ufs.find(
        (uf) => uf.uficms_uf == this.currentEmpresa.uf
      );
      console.log(selecionado_uf_icms);

      const transformedArray = Object.keys(selecionado_uf_icms)
        .filter((key) => key.startsWith("uficms_"))
        .map((key) => {
          return {
            //
            uficms_uf: key.split("_")[1].toUpperCase(),
            uficms_value: selecionado_uf_icms[key],
          };
        });

      console.log(transformedArray);
      this.vet_uf_icms_new_objeto = transformedArray;
    },
    dlt_icms(objeto, index) {
      console.log(objeto);
      console.log(index);
      if (this.tabs_icms.length > 1) {
        this.tabs_icms.splice(index, 1);
      }
      console.log(this.tabs_icms);
    },
    add_icms() {
      this.tabs_icms = [];
      this.vet_uf_icms_new_objeto.forEach((item) => {
        console.log(item.uficms_uf);
        if (item.uficms_uf != "uf") {
          let objeto = {
            uf: item.uficms_uf,
            icms: item.uficms_value,
            red_base: null,
            margem: null,
            diferimento: null,
          };
          console.log(objeto);
          this.tabs_icms.push({ ...objeto });
        }
      });
      // let objeto = {
      //   uf: null,
      //   icms: null,
      //   red_base: null,
      //   margem: null,
      //   diferimento: null,
      // };
      this.produto.vet_uf_icms_new_objeto = this.vet_uf_icms_new_objeto;
      this.produto.tabs_icms_new = this.tabs_icms;
      console.log(this.tabs_icms);
    },
    setDelete(objeto) {
      var index = this.produto.compoe_produtos.indexOf(objeto);
      console.log(objeto);
      if (index > -1) {
        this.produto.compoe_produtos.splice(index, 1);
        this.delete_composicao_prod(objeto);
      }
    },
    async delete_composicao_prod(objeto) {
      try {
        let response = await http
          .delete(
            "/prodcomp/" + objeto.id + "?empresa_id=" + this.currentEmpresa.id,
            objeto
          )
          .catch((error) => {
            // this.offLoader();
            //(error.response);
            this.makeToast("danger", error.response.data.error);
          });

        if (response.status && response.status === 200) {
          // this.getData();
          //this.offLoader();
          this.makeToast("warning", "Registro excluído");
        }
      } catch (error) {
        //this.offLoader();
        //  console.log(error);
      }
    },
    verifica_se_tem_composição() {
      console.log("estou em verifica sem tem composição");
      console.log(this.oldProduto);
      if (
        this.oldProduto.compoe_produtos != null &&
        this.oldProduto.compoe_produtos != "" &&
        this.oldProduto.compoe_produtos != undefined
      ) {
        if (this.oldProduto.compoe_produtos.length > 0) {
          this.vetor_produtos = this.oldProduto.compoe_produtos;
        }
      }
    },
    formata_custo() {
      // this.produto.preco_custo = this.(this.produto.preco_custo)
      let custo = parseFloat(this.produto.preco_custo);
      this.produto.preco_custo = this.formatterCurrBR(custo);
      console.log(this.produto.preco_custo);

      if (
        this.produto.preco_atacado != null &&
        this.produto.preco_atacado != undefined
      ) {
        let custo = parseFloat(this.produto.preco_atacado);
        this.produto.preco_atacado = this.formatterCurrBR(custo);
      }
    },
    formatInput_valor() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.produto.preco_custo.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.produto.preco_custo = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
      ///this.calcPreco()
    },
    inputPreenchido(index) {
      // Obtém o valor do input com base no índice passado como parâmetro
      const valorInput = this.$refs.tabelaCelulas[index].value;
      const nomeHead = this.tabelas[index].ttp_nome; //  pega o nome do arrya tabela apartir do index;

      // Verifica se o valor do input existe e se não há objeto atual ou o índice do objeto atual é diferente do índice do input atual
      if (
        valorInput &&
        (!this.objetoAtual || this.objetoAtual.index !== index)
      ) {
        // se o valor do input existe e não há objeto atual ou o índice do objeto atual é diferente do índice do input atual

        // Cria um novo objeto com o índice do input e o valor do input
        this.objetoAtual = { index, valorInput, nomeHead };
        // Adiciona o objeto atual na lista de inputs preenchidos
        this.inputsPreenchidos.push(this.objetoAtual);

        // Se o valor do input existe e o índice do objeto atual é igual ao índice do input atual
      } else if (valorInput && this.objetoAtual.index === index) {
        // Atualiza o valor do objeto atual com o novo valor do input
        this.objetoAtual.valorInput = valorInput;

        // Se o valor do input não existe, remove o objeto atual correspondente ao índice do input
      } else if (!valorInput) {
        // Encontra o índice do objeto atual na lista de inputs preenchidos com base no índice do input
        const indexToRemove = this.inputsPreenchidos.findIndex(
          (input) => input.index === index
        );

        // Se o objeto atual é encontrado na lista de inputs preenchidos, remove-o e define o objeto atual como null
        if (indexToRemove !== -1) {
          // 1 para remover um elemento;
          this.inputsPreenchidos.splice(indexToRemove, 1);
          this.objetoAtual = null;
        }
      }

      // console.log(this.inputsPreenchidos)

      //     this.inputsPreenchidos.forEach(function(input) {
      //      //console.log(input.nomeHead);

      // });

      const objetoUnico = this.inputsPreenchidos.reduce((resultado, input) => {
        resultado[input.nomeHead] = input.valorInput;
        return resultado;
      }, {});

      this.produto.tabela_preco = objetoUnico;
      //console.log(objetoUnico);
    },
    recebe() {
      // é chamado pela função form;
      this.produto.tipos_tabelas = this.inputsPreenchidos;
    },

    // tentar mudar o indice do array para o mesmo valor do index
    // fazer um valor é percorrrer apenas o index do objeto e não o indice do array
    // pq pode dar erro;

    // verificar o index de cada objeto com o index dos nomes do head da tabela para
    // mandar para api;

    // compara o id do produto com o id_produto da tabella composicaos;

    async Update_composicao(evento, index) {
      // pesquisa o Objeto no vetor pelo index(que é o id ) , e compara com o id
      // de cada objeto ate encontar o desejado;
      let composicao = this.list_composicao.find((p) => p.id === index);
      //let id = index;
      //console.log(composicao)
      //console.log(id)
      evento.preventDefault();

      let response = await http
        .put("/composicao/" + composicao.id, composicao)
        .catch((error) => {
          this.offLoader();
          this.makeToast("danger", error.response);
        });

      if (response.status === 200) {
        //console.log("alterou")
        this.makeToast("success", "Registro alterado");
      } else {
        //console.log("nao alterou")
      }
    },
    async Update_fornecedor_integrar(evento, index) {
      // pesquisa o Objeto no vetor pelo index(que é o id ) , e compara com o id
      // de cada objeto ate encontar o desejado;
      let fornecedores_inte = this.list_fornecedores_integration.find(
        (p) => p.id === index
      );
      //let id = index;
      //console.log(composicao)
      //console.log(id)
      evento.preventDefault();

      let response = await http
        .put("/integrar/" + fornecedores_inte.id, fornecedores_inte)
        .catch((error) => {
          this.makeToast("danger", error.response);
        });

      if (response.status === 200) {
        //console.log("alterou")
        this.makeToast("success", "Registro alterado");
      } else {
        //console.log("nao alterou")
      }
    },

    async Excluir(evento, index) {
      let composicao = this.list_composicao.find((p) => p.id === index);

      let response = await http
        .delete("/composicao/" + composicao.id)
        .catch((error) => {
          this.makeToast("danger", error.response);
        });

      if (response.status === 200) {
        // console.log("Excluiu ")
        this.makeToast("success", "Registro excluido");
      } else {
        console.log("nao Excluiu");
      }
    },
    async ExcluirFornecedores(evento, index) {
      evento.preventDefault();

      let fornecedores_inte = this.list_fornecedores_integration.find(
        (p) => p.id === index
      );

      let response = await http
        .delete("/integrar/" + fornecedores_inte.id)
        .catch((error) => {
          this.makeToast("danger", error.response);
        });

      if (response.status === 200) {
        // console.log("Excluiu ")
        this.makeToast("success", "Registro excluido");
      } else {
        console.log("nao Excluiu");
      }
    },

    async get_composicao_produto() {
      //  console.log(this.produto)

      let response = await http.get(
        "/composicao/pesquisa?id=" + this.produto.id
      );

      if (response.status === 200) {
        // alert("entrou");
        this.list_composicao = response.data;
        //console.log(this.list_composicao)
      } else {
        console.log("naao entrou");
      }
    },
    async get_fornecedor_integrar() {
      //  console.log(this.produto)

      let response = await http.get("/integrar/pesquisa?id=" + this.produto.id);

      if (response.status === 200) {
        // alert("entrou");
        this.list_fornecedores_integration = response.data;
        console.log(this.list_fornecedores_integration);
      } else {
        console.log("naao entrou");
      }
    },

    async getTipoTabela() {
      try {
        let response = await http.get(
          "/tipo-tabela-preco?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          this.tabelas = response.data;
          //console.log(this.tabelas);

          setTimeout(() => {
            // this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          this.tabelas = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          // this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },

    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    setNewTitle() {
      this.$emit("newTitle", "Alterar Produto " + this.produto.id);
    },
    calcPreco() {
      this.produto.produto_margemlucro = this.produto.produto_margemlucro
        ? this.produto.produto_margemlucro.replace("%", "")
        : 0;
      if (
        this.produto.preco_custo.includes(".") &&
        this.produto.preco_custo.includes(",")
      ) {
        var custo = this.produto.preco_custo.replace(".", "").replace(",", ".");
        //console.log(this.produto.preco_custo)
      } else if (
        !this.produto.preco_custo.includes(".") &&
        this.produto.preco_custo.includes(",")
      ) {
        custo = this.produto.preco_custo.replace(",", ".");
      }
      if (
        this.produto.preco_custo &&
        parseFloat(this.produto.preco_custo) > 0
      ) {
        if (
          this.produto.produto_margemlucro &&
          parseFloat(this.produto.produto_margemlucro) > 0
        ) {
          custo = parseFloat(custo);
          console.log(custo);
          var margem = parseFloat(
            this.produto.produto_margemlucro.replace(",", ".")
          );
          var preco = ((margem + 100) / 100) * custo;
          //this.produto.preco = this.formatterCurrBR(preco.toFixed(2).replace(".", ","));
          this.produto.preco = preco;
          //this.produto.preco_custo = this.formatterCurrBR(this.produto.preco_custo)
          this.preco_total = this.formatterCurrBR(preco);
        }
      }
      this.produto.preco_custo = this.formatterCurrBR(this.produto.preco_custo);

      this.produto.produto_margemlucro = this.produto.produto_margemlucro + "%";
    },
    compositorSelecionado(event) {
      this.composicao.cod_produto = event.id;
      this.composicao.custo = event.preco_custo;
      this.composicao.venda = event.preco;

      this.composicao.custoMoeda = this.formatterFloatBR(event.preco_custo);
      this.composicao.vendaMoeda = this.formatterFloatBR(event.preco);
    },
    inputComposicaoQuantidade(evento) {
      let value;
      value = this.formatInputFloat(evento);

      this.composicao.quantidadeFloat = value;
      this.composicao.quantidade = this.coverte_para_float_valor_input(value);
    },
    inputComposicaoCusto(evento) {
      let value;
      value = this.formatInputFloat(evento);

      this.composicao.custoMoeda = value;
      this.composicao.custo = this.coverte_para_float_valor_input(value);
    },
    inputComposicaoVenda(evento) {
      let value;
      value = this.formatInputFloat(evento);

      this.composicao.vendaMoeda = value;
      this.composicao.venda = this.coverte_para_float_valor_input(value);
    },
    back() {
      this.$emit("back");
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit() {
      //this.recebe()

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.produto.$error) {
        this.makeToast("danger", "Erro de validação!");
      } else {
        /// console.log("aqui no else");
        this.setPut();
      }
    },
    formatInput_valor_atacado() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.produto.preco_atacado.replace(/\D/g, "");
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.produto.preco_atacado = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
      //this.calcPreco()
    },
    converte_values_tabs_icms() {
      console.log("Estou em Converter Tabs");
      let vetor_original = [...this.tabs_icms];
      console.log("mostrando vetor original");
      console.log(vetor_original);

      vetor_original.forEach((item, index) => {
        let red_base = this.new_convert_srting_float(item.red_base);
        let margem = this.new_convert_srting_float(item.margem);
        let diferimento = this.new_convert_srting_float(item.diferimento);

        this.tabs_icms[index].red_base = red_base;
        this.tabs_icms[index].margem = margem;
        this.tabs_icms[index].diferimento = diferimento;
      });
      console.log("mostrando vetor com valores convertidos");
      console.log(this.tabs_icms);
    },
    setPut() {
      if (this.tabs_icms.length == 0) {
        this.converte_values_tabs_icms();
      }
      this.produto.tabs_icms_new = this.tabs_icms;
      // logica para o valor do custo
      // let valor_custo = parseFloat(this.produto.preco_custo);
      // let srting_custo = valor_custo.toString();
      // this.produto.preco_custo = srting_custo;
      if (
        this.produto.preco_custo.includes(".") &&
        this.produto.preco_custo.includes(",")
      ) {
        let valor_atacado = this.produto.preco_custo
          .replace(".", "")
          .replace(",", ".");
        let Valor_atacado_float = parseFloat(valor_atacado);
        this.produto.preco_custo = Valor_atacado_float.toString();
      } else if (
        !this.produto.preco_custo.includes(".") &&
        this.produto.preco_custo.includes(",")
      ) {
        let valor_atacado = this.produto.preco_custo.replace(",", ".");
        let Valor_atacado_float = parseFloat(valor_atacado);
        this.produto.preco_custo = Valor_atacado_float.toString();
      }
      if (
        this.produto.preco_atacado.includes(".") &&
        this.produto.preco_atacado.includes(",")
      ) {
        let valor_atacado = this.produto.preco_atacado
          .replace(".", "")
          .replace(",", ".");
        let Valor_atacado_float = parseFloat(valor_atacado);
        this.produto.preco_atacado = Valor_atacado_float.toString();
      } else if (
        !this.produto.preco_atacado.includes(".") &&
        this.produto.preco_atacado.includes(",")
      ) {
        let valor_atacado = this.produto.preco_atacado.replace(",", ".");
        let Valor_atacado_float = parseFloat(valor_atacado);
        this.produto.preco_atacado = Valor_atacado_float.toString();
      }
      console.log(this.produto.produto_margemlucro);
      this.produto.produto_margemlucro =
        this.produto.produto_margemlucro.replace("%", "");
      console.log("dentro de edit");
      console.log(this.produto);
      this.$emit("doPut", this.produto);
      this.back();
    },
    selectGrupo(value) {
      this.produto.grupo_id = value ? value.id : null;
    },
    selectUnidade(value) {
      this.produto.unidade_id = value ? value.id : null;
      this.produto.um = value ? value.name : null;
    },
    selectTipoProduto(value) {
      this.produto.idtipo = value ? value.id : null;
    },
    selectOrig(value) {
      this.produto.produto_orig = value ? value.id : null;
    },
    selectCFOP(value) {
      this.produto.produto_cfop = value ? value.id : null;
    },
    // Impostos Saídas
    selectCST(value) {
      this.produto.produto_cst = value ? value.id : null;
    },
    selectCSTIPI(value) {
      this.produto.produto_cstipi = value ? value.id : null;
    },
    selectCSTPIS(value) {
      this.produto.produto_cstpis = value ? value.id : null;
    },
    selectCSTCOFINS(value) {
      this.produto.produto_cstcofins = value ? value.id : null;
    },
    // Impostos Entradas
    selectCSTe(value) {
      this.produto.produto_cste = value ? value.id : null;
    },
    selectCSTIPIe(value) {
      this.produto.produto_cstipie = value ? value.id : null;
    },
    selectCSTPISe(value) {
      this.produto.produto_cstpise = value ? value.id : null;
    },
    selectCSTCOFINSe(value) {
      this.produto.produto_cstcofinse = value ? value.id : null;
    },
    selectClassificacaoSPED(value) {
      this.produto.produto_class_sped = value ? value.id : null;
    },
    setValues() {
      var tipoProduto = this.listTiposProduto.filter((valor) => {
        return valor.id === this.produto.idtipo;
      });
      this.tipoProdutoSelecionado = tipoProduto;

      var unidade = this.listUnidades.filter((valor) => {
        return valor.id === this.produto.unidade_id;
      });
      this.unidadeSelecionada = unidade;

      var grupo = this.listGrupos.filter((valor) => {
        return valor.id === this.produto.grupo_id;
      });
      this.grupoSelecionado = grupo;

      var origem = this.listOrigem.filter((valor) => {
        return valor.id === parseInt(this.produto.produto_orig);
      });
      this.origSelecionado = origem;

      var cfop = this.listCFOP.filter((valor) => {
        return valor.id === parseInt(this.produto.produto_cfop);
      });
      this.cfopSelecionado = cfop;

      var cstICMS = this.listCSTsICMS.filter((valor) => {
        return valor.id === parseInt(this.produto.produto_cst);
      });
      this.CSTICMSSelecionado = cstICMS;

      var cstIPI = this.listCSTsIPI.filter((valor) => {
        return valor.id === this.produto.produto_cstipi;
      });
      this.CSTIPISelecionado = cstIPI;

      var cstPIS = this.listCSTsPIS.filter((valor) => {
        return valor.id === this.produto.produto_cstpis;
      });
      this.CSTPISSelecionado = cstPIS;

      var cstCOFINS = this.listCSTsCOFINS.filter((valor) => {
        return valor.id === this.produto.produto_cstcofins;
      });
      this.CSTCOFINSSelecionado = cstCOFINS;

      var cstICMSe = this.listCSTsICMS.filter((valor) => {
        return valor.id === parseInt(this.produto.produto_cste);
      });
      this.CSTICMSeSelecionado = cstICMSe;

      var cstIPIe = this.listCSTsIPI.filter((valor) => {
        return valor.id === this.produto.produto_cstipie;
      });
      this.CSTIPIeSelecionado = cstIPIe;

      var cstPISe = this.listCSTsPIS.filter((valor) => {
        return valor.id === this.produto.produto_cstpise;
      });
      this.CSTPISeSelecionado = cstPISe;

      var cstCOFINSe = this.listCSTsCOFINS.filter((valor) => {
        return valor.id === this.produto.produto_cstcofinse;
      });
      this.CSTCOFINSeSelecionado = cstCOFINSe;

      var classificacaoSPED = this.listClassificacaoSPED.filter((valor) => {
        return valor.id === parseInt(this.produto.produto_class_sped);
      });
      this.classificacaoSPEDSelecionado = classificacaoSPED;
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="accordion" role="tablist">
      <form role="form" class="form-horizontal">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.produto-dados-gerais variant="light"
              >Dados gerais</b-button
            >
          </b-card-header>
          <b-collapse
            id="produto-dados-gerais"
            visible
            accordion="produto-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="produto_id" class="col-form-label"
                      >Código</label
                    >
                    <div class="col">
                      <input
                        disabled
                        v-model="produto.id"
                        class="form-control text-right"
                        type="text"
                        placeholder="Gerado pelo sistema"
                        id="produto_id"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="produto_codbarras" class="col-form-label"
                      >Referência</label
                    >
                    <div class="col">
                      <input
                        v-model="produto.codbarras"
                        class="form-control text-right"
                        type="text"
                        placeholder="Referência (Antigo código interno)"
                        id="produto_codbarras"
                        :class="{
                          'is-invalid':
                            submitted && $v.produto.codbarras.$error,
                        }"
                        ref="codbarras"
                      />
                      <div
                        v-if="submitted && $v.produto.codbarras.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.produto.codbarras.required">
                          {{ $t("mesages.validation.required") }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="idtipo" class="col-form-label">Tipo</label>
                    <div class="col">
                      <multiselect
                        v-model="tipoProdutoSelecionado"
                        :options="listTiposProduto"
                        placeholder="Selecione"
                        track-by="id"
                        deselect-label="Pressione ENTER para remover"
                        label="name"
                        @input="selectTipoProduto"
                      >
                        <template slot="singleLabel" slot-scope="{ option }"
                          ><strong>{{ option.name }}</strong></template
                        >
                      </multiselect>
                      <b-form-invalid-feedback
                        v-if="submitted && $v.produto.idtipo.$error"
                        :state="$v.produto.idtipo.required"
                      >
                        {{ $t("mesages.validation.selectone") }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div>
                    <h5 class="font-size-14 mb-4">Classe</h5>
                    <div class="form-check">
                      <input
                        v-model="produto.produto_m_s"
                        type="radio"
                        name="classe"
                        id="classe1"
                        value="P"
                        checked="checked"
                        class="form-check-input"
                      />
                      <label for="classe1" class="form-check-label"
                        >Produto</label
                      >
                    </div>
                    <div class="form-check">
                      <input
                        v-model="produto.produto_m_s"
                        type="radio"
                        name="classe"
                        id="classe-servico"
                        value="S"
                        class="form-check-input"
                      />
                      <label for="classe-servico" class="form-check-label"
                        >Serviço</label
                      >
                    </div>
                    <div class="form-check">
                      <input
                        v-model="produto.produto_m_s"
                        type="radio"
                        name="classe"
                        id="classe-mercadoria"
                        value="M"
                        class="form-check-input"
                      />
                      <label for="classe-mercadoria" class="form-check-label"
                        >Mercadoria</label
                      >
                    </div>
                    <b-form-invalid-feedback
                      v-if="submitted && $v.produto.produto_m_s.$error"
                      :state="$v.produto.produto_m_s.required"
                    >
                      {{ $t("mesages.validation.selectone") }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </div>
              <div
                id="gourp-produto-nome"
                class="form-row form-group"
                role="group"
              >
                <label for="produto-nome" class="col-sm-2 col-form-label"
                  >Nome do Produto</label
                >
                <div class="col">
                  <input
                    v-model="produto.produto_nome"
                    class="form-control"
                    type="text"
                    placeholder="Nome do Produto"
                    id="produto_nome"
                    maxlength="100"
                    :class="{
                      'is-invalid': submitted && $v.produto.produto_nome.$error,
                    }"
                  />
                  <div
                    v-if="submitted && $v.produto.produto_nome.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.produto.produto_nome.required">
                      {{ $t("mesages.validation.required") }}</span
                    >
                  </div>
                </div>
              </div>
              <div
                id="gourp-produto-descricao"
                class="form-row form-group"
                role="group"
              >
                <label for="produto-descricao" class="col-sm-2 col-form-label"
                  >Descrição do Produto</label
                >
                <div class="col">
                  <input
                    v-model="produto.produto_descricao"
                    class="form-control"
                    type="text"
                    placeholder="Descrição do Produto"
                    id="produto_nome"
                    maxlength="100"
                    :class="{
                      'is-invalid':
                        submitted && $v.produto.produto_descricao.$error,
                    }"
                  />
                  <div
                    v-if="submitted && $v.produto.produto_descricao.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.produto.produto_descricao.required">
                      {{ $t("mesages.validation.required") }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="produto_produto_ncm" class="col-form-label"
                      >N. C. M.</label
                    >
                    <div class="col">
                      <input
                        v-model="produto.produto_ncm"
                        class="form-control"
                        type="text"
                        placeholder="Digite o NCM"
                        id="produto_produto_ncm"
                        :class="{
                          'is-invalid':
                            submitted && $v.produto.produto_ncm.$error,
                        }"
                      />
                      <div
                        v-if="submitted && $v.produto.produto_ncm.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.produto.produto_ncm.required">
                          {{ $t("mesages.validation.required") }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="produto_cest" class="col-form-label"
                      >CEST</label
                    >
                    <div class="col">
                      <input
                        v-model="produto.produto_cest"
                        class="form-control"
                        type="text"
                        placeholder="Digite o CEST"
                        id="produto_produto_cest"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="produto_preco_custo" class="col-form-label"
                      >Custo</label
                    >
                    <div class="col">
                      <input
                        @input="formatInput_valor()"
                        v-model="produto.preco_custo"
                        class="form-control text-right"
                        type="text"
                        placeholder="R$ 0,00"
                        id="produto_preco_custo"
                        :class="{
                          'is-invalid':
                            submitted && $v.produto.preco_custo.$error,
                        }"
                      />
                      <div
                        v-if="submitted && $v.produto.preco_custo.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.produto.preco_custo.required">
                          {{ $t("mesages.validation.required") }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label
                      for="produto_produto_margemlucro"
                      class="col-form-label"
                      >Margem</label
                    >
                    <div class="col">
                      <input
                        @input="calcPreco"
                        v-model="produto.produto_margemlucro"
                        v-percent
                        class="form-control text-right"
                        type="text"
                        placeholder="0,00%"
                        id="produto_produto_margemlucro"
                        :class="{
                          'is-invalid':
                            submitted && $v.produto.produto_margemlucro.$error,
                        }"
                      />
                      <div
                        v-if="
                          submitted && $v.produto.produto_margemlucro.$error
                        "
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.produto.produto_margemlucro.required">
                          {{ $t("mesages.validation.required") }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="produto_preco" class="col-form-label"
                      >Venda</label
                    >
                    <div class="col">
                      <input
                        v-model="preco_total"
                        class="form-control text-right"
                        type="text"
                        placeholder="R$ 0,00"
                        id="produto_preco"
                        :class="{
                          'is-invalid': submitted && $v.produto.preco.$error,
                        }"
                      />
                      <div
                        v-if="submitted && $v.produto.preco.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.produto.preco.required">
                          {{ $t("mesages.validation.required") }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="produto_preco_atacado" class="col-form-label"
                      >Atacado</label
                    >
                    <div class="col">
                      <input
                        @input="formatInput_valor_atacado()"
                        v-model="produto.preco_atacado"
                        class="form-control text-right"
                        type="text"
                        placeholder="R$ 0,00"
                        id="produto_preco_atacado"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="produto_custo_medio" class="col-form-label"
                      >Custo médio</label
                    >
                    <div class="col">
                      <input
                        disabled
                        v-model="custo_medio"
                        class="form-control text-right"
                        type="text"
                        placeholder="R$ 0,00"
                        id="produto_custo_medio"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="produto_um" class="col-form-label"
                      >Unidade</label
                    >
                    <div class="col">
                      <multiselect
                        v-model="unidadeSelecionada"
                        :options="listUnidades"
                        placeholder="Selecione"
                        track-by="id"
                        deselect-label="Pressione ENTER para remover"
                        label="name"
                        @input="selectUnidade"
                      >
                        <template slot="singleLabel" slot-scope="{ option }"
                          ><strong>{{ option.name }}</strong></template
                        >
                      </multiselect>
                      <b-form-invalid-feedback
                        v-if="submitted && $v.produto.unidade_id.$error"
                        :state="$v.produto.unidade_id.required"
                      >
                        {{ $t("mesages.validation.selectone") }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="produto_qtd" class="col-form-label"
                      >Quantidade</label
                    >
                    <div class="col">
                      <input
                        v-model="produto.qtd"
                        class="form-control text-right"
                        type="text"
                        placeholder="0,000"
                        id="produto_qtd"
                        :class="{
                          'is-invalid': submitted && $v.produto.qtd.$error,
                        }"
                      />
                      <div
                        v-if="submitted && $v.produto.qtd.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.produto.qtd.required">
                          {{ $t("mesages.validation.required") }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="produto_id" class="col-form-label"
                      >Opcionais</label
                    >
                    <div class="col">
                      <b-form-checkbox
                        id="produto-ativo"
                        v-model="produto.ativo"
                        name="ativo"
                        value="-1"
                        unchecked-value="0"
                      >
                        Ativo
                      </b-form-checkbox>
                    </div>
                    <div class="col">
                      <b-form-checkbox
                        id="produto-produto_controla_estoque"
                        v-model="produto.produto_controla_estoque"
                        name="produto_controla_estoque"
                        value="-1"
                        unchecked-value="0"
                      >
                        Controla estoque
                      </b-form-checkbox>
                    </div>
                    <div class="col">
                      <b-form-checkbox
                        id="produto-produto_balanca"
                        v-model="produto.produto_balanca"
                        name="produto_balanca"
                        value="-1"
                        unchecked-value="0"
                      >
                        Balança
                      </b-form-checkbox>
                    </div>
                    <div class="col">
                      <b-form-checkbox
                        id="produto-produto_produzido"
                        v-model="produto.produto_produzido"
                        name="produto_produzido"
                        value="-1"
                        unchecked-value="0"
                      >
                        Produzido
                      </b-form-checkbox>
                    </div>
                    <div class="col">
                      <b-form-checkbox
                        id="produto-produto_materiaprima"
                        v-model="produto.produto_materiaprima"
                        name="produto_materiaprima"
                        value="-1"
                        unchecked-value="0"
                      >
                        Matéria prima
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-4">
                  <div class="form-group">
                    <label for="produto_idgrupo" class="col-form-label"
                      >Grupo</label
                    >
                    <div class="col">
                      <multiselect
                        v-model="grupoSelecionado"
                        :options="listGrupos"
                        placeholder="Selecione"
                        track-by="id"
                        deselect-label="Pressione ENTER para remover"
                        label="name"
                        @input="selectGrupo"
                      >
                        <template slot="singleLabel" slot-scope="{ option }"
                          ><strong>{{ option.name }}</strong></template
                        >
                      </multiselect>
                      <b-form-invalid-feedback
                        v-if="submitted && $v.produto.grupo_id.$error"
                        :state="$v.produto.grupo_id.required"
                      >
                        {{ $t("mesages.validation.selectone") }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="produto_cean" class="col-form-label"
                      >GTIN (Cód. barras)</label
                    >
                    <div class="col">
                      <input
                        v-model="produto.produto_cean"
                        class="form-control text-right"
                        type="text"
                        placeholder="7899999999999"
                        id="produto_produto_cean"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="produto.produto_controla_estoque == -1">
                <div class="col-md-10">
                  <Label>Local Estoque</Label>
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    v-model="select_local_estoque_id"
                    @change="seleciona_local_estoque($event)"
                  >
                    <option value="-1">selecione o Local de Estoque</option>
                    <option
                      v-for="(item, index) in locais_estoques"
                      :key="index"
                      :value="item.id"
                    >
                      {{ item.descricao }}
                    </option>
                  </select>
                </div>
                <div class="col-md-2 mt-4">
                  <button class="btn btn-success mt-1" @click="adc_mt_prima()">Add Estoque</button>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.produto-composicao variant="light"
              >Composição</b-button
            >
          </b-card-header>
          <b-collapse
            id="produto-composicao"
            accordion="produto-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card-text>{{ text }}</b-card-text>
              <!-- @filtered="onFiltered" -->
              <!-- :per-page="perPage" -->
              <!-- :filter-included-fields="filterOn" -->
              <!-- :sort-by.sync="sortBy" -->
              <!-- :sort-desc.sync="sortDesc" -->
              <!-- :current-page="currentPage" -->
              <!-- :filter="filter" -->
              <b-table
                :items="produto.compoe_produtos"
                :fields="fieldsComposisoes"
                responsive="sm"
                :hover="true"
              >
                <template #cell(acoes)="row">
                  <b-dropdown
                    v-bind:id="'dropdown-' + row.item.id"
                    class="m-md-2"
                  >
                    <template #button-content>
                      <i data-v-6289eca4="" class="bx ri-menu-line"></i>
                    </template>
                    <b-dropdown-item @click="setDelete(row.item)"
                      ><i class="bx ri-eraser-fill"></i>
                      Excluir</b-dropdown-item
                    >
                    <b-dropdown-item @click="alteraComposicao(row.item)"
                      ><i class="bx ri-eraser-fill"></i>
                      alterar</b-dropdown-item
                    >
                    <b-dropdown-item @click="addestoque(row.item)"
                      ><i class="bx ri-eraser-fill"></i> Aumentar
                      Estoque</b-dropdown-item
                    >
                  </b-dropdown>
                </template>
                <template #cell(quantidade)="row">
                  <input
                    v-model="row.item.quantidade"
                    type="text"
                    class="form-control text-left"
                  />
                </template>
                <template #cell(venda)="row">
                  <input
                    @input="formatInputFloat($event, row.item, 'venda')"
                    v-model="row.item.venda"
                    type="text"
                    class="form-control text-left"
                  />
                </template>
                <template #cell(custo)="row">
                  <input
                    @input="formatInputFloat($event, row.item, 'custo')"
                    v-model="row.item.custo"
                    type="text"
                    class="form-control text-left"
                  />
                </template>
                <!-- listUnidades -->
                <template #cell(un)="row">
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    v-model="row.item.un"
                    @change="seleciona_unidade_comp_edit($event, row.item.un)"
                  >
                    <option value="-1">selecione a Unidade</option>
                    <option
                      v-for="(item, index) in listUnidades"
                      :key="index"
                      :value="item.name"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </template>
                <template #cell(produto_nome)="row">
                  <input
                    v-model="row.item.produto_nome"
                    type="text"
                    class="form-control text-left"
                  />
                </template>
              </b-table>
            </b-card-body>
            <div class="row" v-if="produto.add_estoque == -1">
              <div class="col-md-12">
                   <Label>{{"Selecione o Estoque"}}</Label>
                   <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    v-model="produto.loc_esoque"
                    @change="seleciona_estoqe_comp_edit($event)"
                  >
                    <option value="-1">selecione o Local</option>
                    <option
                      v-for="(item, index) in locais_estoques"
                      :key="index"
                      :value="item.id"
                    >
                      {{ item.descricao }}
                    </option>
                  </select>
              </div>
          </div>
          <div class="row d-flex justify-content-center align-items-center mb-4">
            <div class="col-md-12 text-center">
              <b-form-checkbox
                v-model="produto.add_estoque"
                value="-1"
                unchecked-value="0"
              >
                Aumentar Estoque
              </b-form-checkbox>
            </div>
          </div>
          </b-collapse>
          
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1 card-header" role="tab">
            <b-button
              block
              v-b-toggle.produto-integracao-fornecedores
              variant="light"
              >Integração Fornecedores</b-button
            >
          </b-card-header>
          <b-collapse
            id="produto-integracao-fornecedores"
            accordion="produto-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card-text>{{ text }}</b-card-text>
              <b-table-simple>
                <b-thead id="b-head">
                  <b-tr>
                    <b-th class="b-th">Nome Fornecedor</b-th>
                    <b-th class="b-th">Codigo</b-th>
                    <b-th class="b-th">Descricao</b-th>
                    <!-- temporario , so para testar -->
                    <b-th class="b-th">produto_id</b-th>
                    <b-th>&nbsp;</b-th>
                    <b-th>&nbsp;</b-th>
                    <b-th>&nbsp;</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr
                    v-for="(
                      fornecedores_int, index
                    ) in list_fornecedores_integration"
                    :key="(index = fornecedores_int.id)"
                  >
                    <b-td
                      ><input
                        v-model="fornecedores_int.nome"
                        type="text"
                        class="form-control text-left"
                    /></b-td>
                    <b-td
                      ><input
                        v-model="fornecedores_int.codigo"
                        type="text"
                        class="form-control text-left"
                    /></b-td>
                    <b-td
                      ><input
                        v-model="fornecedores_int.descricao"
                        type="text"
                        class="form-control text-left"
                    /></b-td>
                    <b-td
                      ><input
                        v-model="fornecedores_int.produto_id"
                        type="text"
                        class="form-control text-left"
                    /></b-td>

                    <b-td
                      ><button
                        class="btn btn-light"
                        @click="Update_fornecedor_integrar($event, index)"
                      >
                        Editar
                      </button></b-td
                    >
                    <b-td
                      ><button
                        class="btn btn-light"
                        @click="ExcluirFornecedores($event, index)"
                      >
                        Excluir
                      </button></b-td
                    >
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1 card-header" role="tab">
            <b-button block v-b-toggle.produto-impostos variant="light"
              >Impostos</b-button
            >
          </b-card-header>
          <b-collapse
            id="produto-impostos"
            accordion="produto-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <div class="row mb-3">
                <div class="col-md-6">
                  <h6>Origem:</h6>
                  <multiselect
                    v-model="origSelecionado"
                    :options="listOrigem"
                    placeholder="Selecione"
                    object="false"
                    track-by="id"
                    deselect-label="Pressione ENTER para remover"
                    select-label="Pressione ENTER"
                    label="name"
                    @input="selectOrig"
                  >
                    <template slot="singlelabel" slot-scope="{ option }"
                      ><strong>{{ option.name }}</strong></template
                    >
                  </multiselect>
                  <b-form-invalid-feedback
                    v-if="submitted && $v.produto.produto_orig.$error"
                    :state="$v.produto.produto_orig.required"
                  >
                    {{ $t("mesages.validation.selectone") }}
                  </b-form-invalid-feedback>
                </div>
                <div class="col-md-3">
                  <h6>CFOP Padrão:</h6>
                  <multiselect
                    v-model="cfopSelecionado"
                    :options="listCFOP"
                    placeholder="Selecione"
                    object="false"
                    track-by="id"
                    desselect-label="Pressione ENTER para remover"
                    select-label="Pressione ENTER"
                    label="id"
                    @input="selectCFOP"
                  >
                    <template slot="singlelabel" slot-scope="{ option }"
                      ><strong>{{
                        option.id + "-" + option.name
                      }}</strong></template
                    >
                  </multiselect>
                  <b-form-invalid-feedback
                    v-if="submitted && $v.produto.produto_cfop.$error"
                    :state="$v.produto.produto_cfop.required"
                  >
                    {{ $t("mesages.validation.selectone") }}
                  </b-form-invalid-feedback>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <b-tabs
                    pills
                    vertical
                    nav-class="p-0"
                    nav-wrapper-class="col-sm-3"
                    content-class="pt-0 px-2 text-muted"
                  >
                    <b-tab title="Saídas" active title-item-class="mb-2">
                      <b-card-body>
                        <h4 class="card-title mb-4">ICMS Saídas:</h4>
                        <div class="row">
                          <div class="col-md-4">
                            <h6>
                              {{
                                this.currentEmpresa.crt == 1 ? "CSOSN" : "CST"
                              }}:
                            </h6>
                          </div>
                          <div class="col-md-2">
                            <h6>Alíquota:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>% Base Calc.:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>Alíquota ST:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>% Base Calc. ST:</h6>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <multiselect
                              v-model="CSTICMSSelecionado"
                              :options="CSTsFilter"
                              placeholder="Selecione"
                              object="false"
                              track-by="id"
                              desselect-label="Pressione ENTER"
                              label="name"
                              @input="selectCST"
                            >
                              <template
                                slot="singlelabel"
                                slot-scope="{ option }"
                                ><strong>{{ option.name }}</strong></template
                              >
                            </multiselect>
                            <b-form-invalid-feedback
                              v-if="submitted && $v.produto.produto_cst.$error"
                              :state="$v.produto.produto_cst.required"
                            >
                              {{ $t("mesages.validation.selectone") }}
                            </b-form-invalid-feedback>
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_picms"
                              class="form-control text-right"
                              type="text"
                              placeholder="% ICMS"
                              id="produto_produto_picms"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pbc_icms"
                              class="form-control text-right"
                              type="text"
                              placeholder="% Base Cálculo"
                              id="produto_produto_pbc_icms"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_picmsst"
                              class="form-control text-right"
                              type="text"
                              placeholder="% ICMS ST"
                              id="produto_produto_picmsst"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pbc_icmsst"
                              class="form-control text-right"
                              type="text"
                              placeholder="% Base Cálculo ST"
                              id="produto_produto_pbc_icmsst"
                            />
                          </div>
                        </div>
                      </b-card-body>
                      <b-card-body>
                        <h4 class="card-title mb-4">IPI Saídas:</h4>
                        <div class="row">
                          <div class="col-md-4">
                            <h6>CST:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>Cód. Enquad.:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>Alíquota:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>% Base Calc.:</h6>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <multiselect
                              v-model="CSTIPISelecionado"
                              :options="listCSTsIPI"
                              placeholder="Selecione"
                              object="false"
                              track-by="id"
                              desselect-label="Pressione ENTER"
                              label="name"
                              @input="selectCSTIPI"
                            >
                              <template
                                slot="singlelabel"
                                slot-scope="{ option }"
                                ><strong>{{ option.name }}</strong></template
                              >
                            </multiselect>
                            <b-form-invalid-feedback
                              v-if="
                                submitted && $v.produto.produto_cstipi.$error
                              "
                              :state="$v.produto.produto_cstipi.required"
                            >
                              {{ $t("mesages.validation.selectone") }}
                            </b-form-invalid-feedback>
                          </div>
                          <div class="col-md-2">
                            <input
                              maxlength="3"
                              v-model="produto.produto_cenq"
                              class="form-control text-right"
                              type="text"
                              placeholder="Cód. Enquandramento"
                              id="produto_produto_cenq"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pipi"
                              class="form-control text-right"
                              type="text"
                              placeholder="% IPI"
                              id="produto_produto_pipi"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pbc_ipi"
                              class="form-control text-right"
                              type="text"
                              placeholder="% Base Cálculo"
                              id="produto_produto_pbc_ipi"
                            />
                          </div>
                        </div>
                      </b-card-body>
                      <b-card-body>
                        <h4 class="card-title mb-4">PIS Saídas:</h4>
                        <div class="row">
                          <div class="col-md-4">
                            <h6>CST:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>Alíquota:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>% Base Calc.:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>Alíquota ST:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>% Base Calc. ST:</h6>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <multiselect
                              v-model="CSTPISSelecionado"
                              :options="listCSTsPIS"
                              placeholder="Selecione"
                              object="false"
                              track-by="id"
                              desselect-label="Pressione ENTER"
                              label="name"
                              @input="selectCSTPIS"
                              class="produto_cstpis"
                            >
                              <template
                                slot="singlelabel"
                                slot-scope="{ option }"
                                ><strong>{{ option.name }}</strong></template
                              >
                            </multiselect>
                            <b-form-invalid-feedback
                              v-if="
                                submitted && $v.produto.produto_cstpis.$error
                              "
                              :state="$v.produto.produto_cstpis.required"
                            >
                              {{ $t("mesages.validation.selectone") }}
                            </b-form-invalid-feedback>
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_ppis"
                              class="form-control text-right"
                              type="text"
                              placeholder="% PIS"
                              id="produto_produto_ppis"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pbc_pis"
                              class="form-control text-right"
                              type="text"
                              placeholder="% Base Cálculo"
                              id="produto_produto_pbc_pis"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_ppisst"
                              class="form-control text-right"
                              type="text"
                              placeholder="% PIS ST"
                              id="produto_produto_ppisst"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pBC_pisst"
                              class="form-control text-right"
                              type="text"
                              placeholder="% Base Cálculo ST"
                              id="produto_produto_pBC_pisst"
                            />
                          </div>
                        </div>
                      </b-card-body>
                      <b-card-body>
                        <h4 class="card-title mb-4">COFINS Saídas:</h4>
                        <div class="row">
                          <div class="col-md-4">
                            <h6>CST:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>Alíquota:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>% Base Calc.:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>Alíquota ST:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>% Base Calc. ST:</h6>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <multiselect
                              v-model="CSTCOFINSSelecionado"
                              :options="listCSTsCOFINS"
                              placeholder="Selecione"
                              object="false"
                              track-by="id"
                              desselect-label="Pressione ENTER"
                              label="name"
                              @input="selectCSTCOFINS"
                              class="produto_cstcofins"
                            >
                              <template
                                slot="singlelabel"
                                slot-scope="{ option }"
                                ><strong>{{ option.name }}</strong></template
                              >
                            </multiselect>
                            <b-form-invalid-feedback
                              v-if="
                                submitted && $v.produto.produto_cstcofins.$error
                              "
                              :state="$v.produto.produto_cstcofins.required"
                            >
                              {{ $t("mesages.validation.selectone") }}
                            </b-form-invalid-feedback>
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pcofins"
                              class="form-control text-right"
                              type="text"
                              placeholder="% COFINS"
                              id="produto_produto_pcofins"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pbc_cofins"
                              class="form-control text-right"
                              type="text"
                              placeholder="% Base Cálculo"
                              id="produto_produto_pbc_cofins"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pcofinsst"
                              class="form-control text-right"
                              type="text"
                              placeholder="% COFINS ST"
                              id="produto_produto_pcofinsst"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pbc_cofinsst"
                              class="form-control text-right"
                              type="text"
                              placeholder="% Base Cálculo ST"
                              id="produto_produto_pbc_cofinsst"
                            />
                          </div>
                        </div>
                      </b-card-body>
                    </b-tab>
                    <b-tab title="Entradas" title-item-class="mb-2">
                      <b-card-body>
                        <h4 class="card-title mb-4">ICMS Entradas:</h4>
                        <div class="row">
                          <div class="col-md-4">
                            <h6>CST/CSOSN</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>Alíquota:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>% Base Calc.:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>Alíquota ST:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>% Base Calc. ST:</h6>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <multiselect
                              v-model="CSTICMSeSelecionado"
                              :options="listCSTsICMS"
                              placeholder="Selecione"
                              object="false"
                              track-by="id"
                              desselect-label="Pressione ENTER"
                              label="name"
                              @input="selectCSTe"
                            >
                              <template
                                slot="singlelabel"
                                slot-scope="{ option }"
                                ><strong>{{ option.name }}</strong></template
                              >
                            </multiselect>
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_picmse"
                              class="form-control text-right"
                              type="text"
                              placeholder="% ICMS"
                              id="produto_produto_picmse"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pbc_icmse"
                              class="form-control text-right"
                              type="text"
                              placeholder="% Base Cálculo"
                              id="produto_produto_pbc_icmse"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_picmsste"
                              class="form-control text-right"
                              type="text"
                              placeholder="% ICMS ST"
                              id="produto_produto_picmsste"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pbc_icmsste"
                              class="form-control text-right"
                              type="text"
                              placeholder="% Base Cálculo ST"
                              id="produto_produto_pbc_icmsste"
                            />
                          </div>
                        </div>
                      </b-card-body>
                      <b-card-body>
                        <h4 class="card-title mb-4">IPI Entradas:</h4>
                        <div class="row">
                          <div class="col-md-4">
                            <h6>CST:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>Cód. Enquad.:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>Alíquota:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>% Base Calc.:</h6>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <multiselect
                              v-model="CSTIPIeSelecionado"
                              :options="listCSTsIPI"
                              placeholder="Selecione"
                              object="false"
                              track-by="id"
                              desselect-label="Pressione ENTER"
                              label="name"
                              @input="selectCSTIPIe"
                            >
                              <template
                                slot="singlelabel"
                                slot-scope="{ option }"
                                ><strong>{{ option.name }}</strong></template
                              >
                            </multiselect>
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_cenqe"
                              class="form-control text-right"
                              type="text"
                              placeholder="Cód. Enquandramento"
                              id="produto_produto_cenqe"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pipie"
                              class="form-control text-right"
                              type="text"
                              placeholder="% IPI"
                              id="produto_produto_pipie"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pbc_ipie"
                              class="form-control text-right"
                              type="text"
                              placeholder="% Base Cálculo"
                              id="produto_produto_pbc_ipie"
                            />
                          </div>
                        </div>
                      </b-card-body>
                      <b-card-body>
                        <h4 class="card-title mb-4">PIS Entradas:</h4>
                        <div class="row">
                          <div class="col-md-4">
                            <h6>CST:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>Alíquota:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>% Base Calc.:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>Alíquota ST:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>% Base Calc. ST:</h6>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <multiselect
                              v-model="CSTPISeSelecionado"
                              :options="listCSTsPIS"
                              placeholder="Selecione"
                              object="false"
                              track-by="id"
                              desselect-label="Pressione ENTER"
                              label="name"
                              @input="selectCSTPISe"
                            >
                              <template
                                slot="singlelabel"
                                slot-scope="{ option }"
                                ><strong>{{ option.name }}</strong></template
                              >
                            </multiselect>
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_ppise"
                              class="form-control text-right"
                              type="text"
                              placeholder="% PIS"
                              id="produto_produto_ppise"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pbc_pise"
                              class="form-control text-right"
                              type="text"
                              placeholder="% Base Cálculo"
                              id="produto_produto_pbc_pise"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_ppisste"
                              class="form-control text-right"
                              type="text"
                              placeholder="% PIS ST"
                              id="produto_produto_ppisste"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pBC_pisste"
                              class="form-control text-right"
                              type="text"
                              placeholder="% Base Cálculo ST"
                              id="produto_produto_pBC_pisste"
                            />
                          </div>
                        </div>
                      </b-card-body>
                      <b-card-body>
                        <h4 class="card-title mb-4">COFINS Entradas:</h4>
                        <div class="row">
                          <div class="col-md-4">
                            <h6>CST:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>Alíquota:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>% Base Calc.:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>Alíquota ST:</h6>
                          </div>
                          <div class="col-md-2">
                            <h6>% Base Calc. ST:</h6>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <multiselect
                              v-model="CSTCOFINSeSelecionado"
                              :options="listCSTsCOFINS"
                              placeholder="Selecione"
                              object="false"
                              track-by="id"
                              desselect-label="Pressione ENTER"
                              label="name"
                              @input="selectCSTCOFINSe"
                            >
                              <template
                                slot="singlelabel"
                                slot-scope="{ option }"
                                ><strong>{{ option.name }}</strong></template
                              >
                            </multiselect>
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pcofinse"
                              class="form-control text-right"
                              type="text"
                              placeholder="% COFINS"
                              id="produto_produto_pcofinse"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pbc_cofinse"
                              class="form-control text-right"
                              type="text"
                              placeholder="% Base Cálculo"
                              id="produto_produto_pbc_cofinse"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pcofinsste"
                              class="form-control text-right"
                              type="text"
                              placeholder="% COFINS ST"
                              id="produto_produto_pcofinsste"
                            />
                          </div>
                          <div class="col-md-2">
                            <input
                              v-model="produto.produto_pbc_cofinsste"
                              class="form-control text-right"
                              type="text"
                              placeholder="% Base Cálculo ST"
                              id="produto_produto_pbc_cofinsste"
                            />
                          </div>
                        </div>
                      </b-card-body>
                    </b-tab>
                    <b-tab title="Tabela ICMS" title-item-class="mb-2">
                      <!-- <b-card-text>{{ text }}</b-card-text> -->
                      <div class="row">
                        <div class="col-md-12">
                          <b-table
                            bordered
                            hover
                            :items="tabs_icms"
                            id="doc-itens"
                            :fields="fields_icms"
                          >
                            <template #cell(uf)="row">
                              <div>
                                <input
                                  v-model="row.item.uf"
                                  type="text"
                                  class="form-control text-left"
                                  placeholder="Digite"
                                />
                              </div>
                            </template>
                            <template #cell(icms)="row">
                              <div>
                                <input
                                  v-model="row.item.icms"
                                  type="text"
                                  class="form-control text-left"
                                  placeholder="Digite"
                                />
                              </div>
                            </template>
                            <template #cell(red_base)="row">
                              <div>
                                <input
                                  v-model="row.item.red_base"
                                  type="text"
                                  class="form-control text-left"
                                  placeholder="Digite"
                                  @input="
                                    formatInputFloat(
                                      $event,
                                      row.item,
                                      'red_base'
                                    )
                                  "
                                />
                              </div>
                            </template>
                            <template #cell(margem)="row">
                              <div>
                                <input
                                  v-model="row.item.margem"
                                  type="text"
                                  class="form-control text-left"
                                  placeholder="Digite"
                                  @input="
                                    formatInputFloat($event, row.item, 'margem')
                                  "
                                />
                              </div>
                            </template>
                            <template #cell(diferimento)="row">
                              <div>
                                <input
                                  v-model="row.item.diferimento"
                                  type="text"
                                  class="form-control text-left"
                                  placeholder="Digite"
                                  @input="
                                    formatInputFloat(
                                      $event,
                                      row.item,
                                      'diferimento'
                                    )
                                  "
                                />
                              </div>
                            </template>
                            <template #cell(excluir)="row">
                              <div>
                                <button
                                  class="btn btn-info"
                                  @click.prevent="
                                    edit_icms(row.item, row.index)
                                  "
                                >
                                  Editar
                                </button>
                              </div>
                            </template>
                          </b-table>
                        </div>
                      </div>
                      <div
                        class="row d-flex justify-content-center align-items-center"
                      >
                        <div class="col-md-3 text-center">
                          <button
                            class="btn btn-success"
                            @click.prevent="add_icms()"
                          >
                            adicionar
                          </button>
                        </div>
                        <div class="col-md-6 text-center">
                          <button class="btn btn-danger">Excluir</button>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab title="Tabela ICMS CST" title-item-class="mb-2">
                      <b-card-text>{{ text }}</b-card-text>
                    </b-tab>
                    <b-tab title="Tabela PIS CST" title-item-class="mb-2">
                      <b-card-text>{{ text }}</b-card-text>
                    </b-tab>
                    <b-tab title="Tabela COFINS CST" title-item-class="mb-2">
                      <b-card-text>{{ text }}</b-card-text>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1 card-header" role="tab">
            <b-button block v-b-toggle.produto-outros variant="light"
              >Outras</b-button
            >
          </b-card-header>
          <b-collapse
            id="produto-outros"
            accordion="produto-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card-body>
                <h4 class="card-title mb-12">SPED:</h4>
                <div class="row">
                  <div class="col-md-12">
                    <h6>Classificação:</h6>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <multiselect
                      v-model="classificacaoSPEDSelecionado"
                      :options="listClassificacaoSPED"
                      placeholder="Selecione"
                      object="false"
                      track-by="id"
                      desselect-label="Pressione ENTER"
                      label="name"
                      @input="selectClassificacaoSPED"
                    >
                      <template slot="singlelabel" slot-scope="{ option }"
                        ><strong>{{
                          option.id + "-" + option.name
                        }}</strong></template
                      >
                    </multiselect>
                    <b-form-invalid-feedback
                      v-if="submitted && $v.produto.produto_class_sped.$error"
                      :state="$v.produto.produto_class_sped.required"
                    >
                      {{ $t("mesages.validation.selectone") }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </b-card-body>
              <b-card-body>
                <div class="row">
                  <div class="col-md-4">
                    <h6>Peso líq.:</h6>
                  </div>
                  <div class="col-md-4">
                    <h6>Peso bruto:</h6>
                  </div>
                  <div class="col-md-4">
                    <h6>Fator exportação:</h6>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <input
                      v-model="produto.produto_pesoliq"
                      class="form-control text-right"
                      type="text"
                      placeholder="Em gramas"
                      id="produto_produto_pesoliq"
                      :class="{
                        'is-invalid':
                          submitted && $v.produto.produto_pesoliq.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.produto.produto_pesoliq.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.produto.produto_pesoliq.required">
                        {{ $t("mesages.validation.required") }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-4">
                    <input
                      v-model="produto.produto_pesobruto"
                      class="form-control text-right"
                      type="text"
                      placeholder="Em gramas"
                      id="produto_produto_pesobruto"
                      :class="{
                        'is-invalid':
                          submitted && $v.produto.produto_pesobruto.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.produto.produto_pesobruto.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.produto.produto_pesobruto.required">
                        {{ $t("mesages.validation.required") }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-4">
                    <input
                      v-model="produto.produto_fatexport"
                      class="form-control text-right"
                      type="text"
                      placeholder="Multiplicador"
                      id="produto_produto_fatexport"
                      :class="{
                        'is-invalid':
                          submitted && $v.produto.produto_fatexport.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.produto.produto_fatexport.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.produto.produto_fatexport.required">
                        {{ $t("mesages.validation.required") }}</span
                      >
                    </div>
                  </div>
                </div>
              </b-card-body>
              <b-card-body>
                <div class="row">
                  <div class="col-md-4">
                    <h6>Cód. Integração:</h6>
                  </div>
                  <div class="col-md-4">
                    <h6>Conta Contábil:</h6>
                  </div>
                  <div class="col-md-4">
                    <h6>Integr. Domínio:</h6>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <input
                      v-model="produto.produto_cod_int"
                      class="form-control text-right"
                      type="text"
                      placeholder="Código do sistema integrado"
                      id="produto_produto_cod_int"
                    />
                  </div>
                  <div class="col-md-4">
                    <input
                      v-model="produto.produto_cta"
                      class="form-control text-right"
                      type="text"
                      placeholder="Código da conta contábil"
                      id="produto_produto_cta"
                    />
                  </div>
                  <div class="col-md-4">
                    <input
                      v-model="produto.produto_intdominio"
                      class="form-control text-right"
                      type="text"
                      placeholder="Multiplicador"
                      id="produto_produto_intdominio"
                    />
                  </div>
                </div>
              </b-card-body>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1 card-header" role="tab">
            <b-button block v-b-toggle.produto-tabelas-preco variant="light"
              >Tabelas de Preços</b-button
            >
          </b-card-header>
          <b-collapse
            id="produto-tabelas-preco"
            accordion="produto-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card-text>{{ text }}</b-card-text>
              <div style="overflow-x: auto">
                <table class="table">
                  <thead id="head">
                    <tr>
                      <th
                        v-for="(tabela, index) in tabelas"
                        :key="index"
                        ref="tabelaCelulas1"
                      >
                        {{ tabela.ttp_nome }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <!--  colocar um v-model-->
                      <td v-for="(tabela, index) in tabelas" :key="index">
                        <input
                          ref="tabelaCelulas"
                          pattern="[0-9]+"
                          required
                          title="Somente números são permitidos"
                          class="form-control1"
                          type="text"
                          placeholder="Preço"
                          @input="inputPreenchido(index)"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- Adicinar uma variavel do tipo -->
            </b-card-body>
          </b-collapse>
        </b-card>
      </form>
    </div>
    <div class="card-body">
      <div class="col-md-12 mt-10">
        <button class="btn btn-light" @click="back">Voltar</button>
        <button class="btn btn-light ml-3" @click="formSubmit()">Gravar</button>
      </div>
    </div>
  </div>
</template>


<style scoped>
.form-control1 {
  width: 130px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>