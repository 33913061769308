<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <input
            @change="handleFileChange"
            type="file"
            id="fileInput"
            style="display: none"
            webkitdirectory
            directory
            multiple
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <H1>imoprtando xml Compra</H1>
          <button class="btn btn-secondary" @click="importa_pedido_xml()">
            Upload
          </button>
        </div>
      </div>
      <!-- inicio -->
      <div class="row" v-if="upload_bool">
        <div class="col-md-12">
          <b-table
            bordered
            hover
            :items="files_array"
            id="doc-itens"
            :fields="fields"
          >
            <template #cell(check)="row">
              <div>
                <b-form-checkbox
                  v-model="row.item.check"
                  @change="seleciona_check(row.item, row.item.check, row.index)"
                >
                </b-form-checkbox>
              </div>
            </template>
          </b-table>
        </div>
      </div>
      <div class="row mt-4 d-flex justify-content-center align-items-center">
        <div class="col-md-12 text-center" v-if="upload_bool">
          <button
            class="btn btn-secondary"
            @click.prevent="envia_pre_nfe_selecionadados()"
          >
            Enviar
          </button>
          <button class="btn btn-success ml-2" @click.prevent="ver()">Ver</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "../../../../helpers/easyindustriaapi/config";
export default {
  props: {},
  data() {
    return {
      guarda_xmls: [],
      xml: null,
      upload_bool: false,
      files_array: [],
      files_array2: [],
      array_files_sem_check: [],
      vetor_new_pre_nfe: [],
      fields: [
        {
          label: "Selecione",
          key: "check",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Nome",
          key: "name",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Tamanho",
          key: "size",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Data",
          key: "lastModifiedDate",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      xmlContents: [],
    };
  },
  created() {},
  mounted() {},
  methods: {
    envia_pre_nfe_selecionadados() {
      console.log("Mostrado veotor ao enviar");
      console.log(this.vetor_new_pre_nfe);
      this.vetor_new_pre_nfe.forEach((f) => {
        this.ler_xml_criar_nfe(f);
      });
    },
    ler_xml_criar_nfe(objeto) {
      console.log(objeto);
      // console.log("estou em ler xml ");
      // console.log(this.fileContent);
      let conteudo = objeto.conteudo;
      if (typeof conteudo === "string") {
        console.log("é uma srting");
      }
      // xml em string //
      let string_xml = conteudo;
      // varivel que vai receber o conteudo convertido de string para xml
      let xmlDocument = new DOMParser().parseFromString(string_xml, "text/xml");
      console.log(xmlDocument);
      let nomes = xmlDocument.querySelectorAll("xProd");
      let cProds = xmlDocument.querySelectorAll("cProd");
      let qComs = xmlDocument.querySelectorAll("qCom");
      let listaNomes = [];

      nomes.forEach((nome, index) => {
        let objeto = {
          xProd: nome.textContent,
          cProd: cProds[index].textContent,
          qCom: qComs[index].textContent,
        };
        listaNomes.push({ ...objeto });
      });
      console.log("Mostrando Lista de Obejto de Itens New");
      console.log(listaNomes);

      // lendo produts detalahda mentente

      let dettElements = xmlDocument.querySelectorAll("det");
      let dets = [];
      dettElements.forEach((emitElement) => {
        let dettObj = {
          xProd:emitElement.querySelector("prod > xProd")?.textContent || "",
          cProd: emitElement.querySelector("prod > cProd")?.textContent || "",
          cEAN: emitElement.querySelector("prod > cEAN")?.textContent || "",
          NCM: emitElement.querySelector("prod > NCM")?.textContent || "",
          CFOP: emitElement.querySelector("prod > CFOP")?.textContent || "",
          uCom: emitElement.querySelector("prod > uCom")?.textContent || "",
          qCom: emitElement.querySelector("prod > qCom")?.textContent || "",
          vUnCom: emitElement.querySelector("prod > vUnCom")?.textContent || "",
          vProd: emitElement.querySelector("prod > vProd")?.textContent || "",
          cEANTrib:
            emitElement.querySelector("prod > cEANTrib")?.textContent || "",
          cBarraTrib:
            emitElement.querySelector("prod > cBarraTrib")?.textContent || "",
          uTrib: emitElement.querySelector("prod > uTrib")?.textContent || "",
          qTrib: emitElement.querySelector("prod > qTrib")?.textContent || "",
          vUnTrib:
            emitElement.querySelector("prod > vUnTrib")?.textContent || "",
          imposto: {
            // ICMS
            icms: {
              orig:
                emitElement.querySelector("imposto > ICMS > ICMSSN102 > orig")
                  ?.textContent || "",
              CSOSN:
                emitElement.querySelector("imposto > ICMS > ICMSSN102 > CSOSN")
                  ?.textContent || "",
            },
            // IPI
            IPI: {
              cEnq:
                emitElement.querySelector("imposto > IPI > cEnq")
                  ?.textContent || "",
              CST:
                emitElement.querySelector("imposto > IPI > IPINT > CST")
                  ?.textContent || "",
            },
            // PIS
            PIS: {
              CST:
                emitElement.querySelector("imposto > PIS > PISNT > CST")
                  ?.textContent || "",
            },
            // COFINS
            COFINS: {
              CST:
                emitElement.querySelector("imposto > COFINS > COFINSNT > CST")
                  ?.textContent || "",
            },
          },
        };

        // Adiciona o objeto emitente ao array
        dets.push(dettObj);
      });
      console.log("mosntrando os dets de Produtos");
      console.log(dets);
      this.vetor_produtos(dets);
      // fim vusca de produos
      let emitElement = xmlDocument.querySelector("emit");
      let emitObj = {
        CNPJ: emitElement.querySelector("CNPJ").textContent,
        xNome: emitElement.querySelector("xNome").textContent,
        xFant: emitElement.querySelector("xFant").textContent,
        enderEmit: {
          xLgr: emitElement.querySelector("enderEmit > xLgr").textContent,
          nro: emitElement.querySelector("enderEmit > nro").textContent,
          xCpl: emitElement.querySelector("enderEmit > xCpl").textContent,
          xBairro: emitElement.querySelector("enderEmit > xBairro").textContent,
          cMun: emitElement.querySelector("enderEmit > cMun").textContent,
          xMun: emitElement.querySelector("enderEmit > xMun").textContent,
          UF: emitElement.querySelector("enderEmit > UF").textContent,
          CEP: emitElement.querySelector("enderEmit > CEP").textContent,
          cPais: emitElement.querySelector("enderEmit > cPais").textContent,
          xPais: emitElement.querySelector("enderEmit > xPais").textContent,
        },
      };
      console.log("mostrando os valores do emitente ");
      console.log(emitObj);
      // ---------------------------------------
      let destElement = xmlDocument.querySelector("dest");
      let destObj = {
        CNPJ: destElement.querySelector("CNPJ").textContent,
        xNome: destElement.querySelector("xNome").textContent,
        // xFant: destElement.querySelector("xFant").textContent,
        enderDest: {
          xLgr: destElement.querySelector("enderDest > xLgr").textContent,
          nro: destElement.querySelector("enderDest > nro").textContent,
          xCpl: destElement.querySelector("enderDest > xCpl").textContent,
          xBairro: destElement.querySelector("enderDest > xBairro").textContent,
          cMun: destElement.querySelector("enderDest > cMun").textContent,
          xMun: destElement.querySelector("enderDest > xMun").textContent,
          UF: destElement.querySelector("enderDest > UF").textContent,
          CEP: destElement.querySelector("enderDest > CEP").textContent,
          cPais: destElement.querySelector("enderDest > cPais").textContent,
          xPais: destElement.querySelector("enderDest > xPais").textContent,
        },
      };
      console.log("mostrando os valores do destinatario");
      console.log(destObj);
      // console.log(destObj.xNome);

      // Processar a tag <ide>
      // let ideElement = xmlDocument.querySelector("ide");
      // let ideObj = {
      //   cNF: ideElement.querySelector("cNF").textContent,
      //   natOp: ideElement.querySelector("natOp").textContent,
      //   dhEmi: ideElement.querySelector("dhEmi").textContent,
      //   dSaida: ideElement.querySelector("dSaida").textContent,
      // };

      // console.log("dados do Ide");
      // console.log(ideObj);

      let objeto_nfe_pre = {};
      // objeto_nfe_pre.DestxNome = destObj.xNome;
      // objeto_nfe_pre.EmitCNPJ = emitObj.CNPJ;
      // objeto_nfe_pre.DestCNPJ = destObj.CNPJ;
      let cont_achou = 0;
      //   listaNomes.forEach((l) => {
      //     let objeto_prod_selecionado = this.produtos_vet.find(
      //       (p) => p.produto_cod_int == l.cProd
      //     );
      //     if (objeto_prod_selecionado == undefined) {
      //       this.makeToast("danger", `produto ${l.xProd} não esta cadastrado`);
      //     } else {
      //       cont_achou++;
      //     }
      //   });
      //pes_cpfcnpj
      //   let selec_cnpj_dest = this.array_pessoas.find(
      //     (p) => p.pes_cpfcnpj == destObj.CNPJ
      //   );

      console.log("mostrando conte achou");
      console.log(cont_achou);
      console.log(this.produtos_vet);
      console.log(this.array_pessoas);

      objeto_nfe_pre.vet2 = listaNomes;
      console.log("mostrando objeto depois do ler xml preNfe");
      console.log(objeto_nfe_pre);

      //   if (objeto_nfe_pre.vet2.length > 0) {
      //     let new_vet = [];
      //     objeto_nfe_pre.vet2.forEach((item) => {
      //       // produto_cod_int
      //       let selecionado_new = this.produtos_vet.find(
      //         (p) => p.produto_cod_int == item.cProd
      //       );
      //       let obj_selecionado = {
      //         descricao: selecionado_new,
      //       };
      //       new_vet.push({ ...obj_selecionado });
      //     });
      //     console.log("mostrando new vet ");
      //     console.log(new_vet);
      //     objeto_nfe_pre.vet = new_vet;
      //     console.log(objeto_nfe_pre.vet);
      //   }
    },
    vetor_produtos(vet_xml_prods) {
      let vet_prods_xml = [...vet_xml_prods];
      let produto = {};
      let vet_prods_post =[]
      vet_prods_xml.forEach((item,index) => {
        produto.codbarras = index;
        produto.produto_nome = item.xProd;
        produto.produto_descricao = item.xProd;
        produto.idtipo = 1;
        produto.produto_local_estoque_id = 1;
        produto.produto_m_s = "M";
        produto.produto_ncm = item.NCM;
        produto.produto_cest = "12345";
        produto.preco_custo = item.vProd;
        produto.preco = item.vProd;
        produto.produto_margemlucro = "40.00";
        produto.preco_atacado = "1.30";
        produto.unidade_id = 5;
        produto.um = "UNIDADE";
        produto.qtd = item.qCom; // Gerar estoque no local padrão
        produto.ativo = -1;
        produto.produto_controla_estoque = -1;
        produto.produto_balanca = 0;
        produto.produto_produzido = 0;
        produto.grupo_id = 1;
        produto.produto_cean = item.cEANTrib;
        produto.produto_orig = item.imposto.icms.orig;
        produto.produto_cst = item.imposto.icms.CSOSN;
        produto.produto_cstpis = item.imposto.PIS.CST;
        produto.produto_cstcofins = item.imposto.COFINS.CST;
        produto.produto_cstipi = item.imposto.IPI.CST;
        produto.produto_picms = "18";
        produto.produto_pbc_icms = "100";
        produto.produto_cfop = item.CFOP;
        produto.produto_picmsst = "0";
        produto.produto_pbc_icmsst = "0";
        produto.produto_cenq = "999";
        produto.produto_pbc_ipi = "0";
        produto.produto_pipi = "0";
        produto.produto_pbc_pis = "0";
        produto.produto_ppis = "0";
        produto.produto_pbc_cofins = "0";
        produto.produto_pcofins = "0";
        produto.produto_ppisst = "0";
        produto.produto_pBC_pisst = "0";
        produto.produto_pbc_cofinsst = "0";
        produto.produto_pcofinsst = "0";
        produto.produto_cste = null;
        produto.produto_picmse = "0";
        produto.produto_pbc_icmse = "0";
        produto.produto_picmsste = "0";
        produto.produto_pbc_icmsste = "0";
        produto.produto_cenqe = null;
        produto.produto_pipie = "0";
        produto.produto_pbc_ipie = "0";
        produto.produto_ppise = "0";
        produto.produto_pbc_pise = "0";
        produto.produto_ppisste = "0";
        produto.produto_pBC_pisste = "0";
        produto.produto_pcofinse = "0";
        produto.produto_pbc_cofinse = "0";
        produto.produto_pcofinsste = "0";
        produto.produto_pbc_cofinsste = "0";
        produto.produto_class_sped = 4;
        produto.produto_pesoliq = "0.100";
        produto.produto_pesobruto = "0.100";
        produto.produto_fatexport = "1.00";
        produto.produto_cod_int = "0001";
        produto.produto_cta = "123";
        produto.produto_intdominio = "000001";
        produto.produto_cod = "1234";
        this.doPost({...produto})
        vet_prods_post.push({...produto});
      });
      console.log("monstrando Vetor Final de Produtos para cadastros")
      console.log(vet_prods_post)
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    async doPost(produto) {
      // var numsStr = produto.produto_margemlucro.replace(/[^0-9]/g, "");
      // produto.produto_margemlucro = parseInt(numsStr);
      // this.changeValues(produto, ",", ".");
      console.log("mostrando produto no post Crrentt");
      console.log(this.currentProduto);
      
      try {
        let response = await http
          .post(
            "/produto?empresa_id=" + 1,
           produto
          )
          .catch((error) => {
            this.error(error.response);
            this.makeToast("danger", error.response);
          });

        if (response.status === 200) {
        
         
          this.makeToast("success", "Registro incluído");
        }
      } catch (error) {
        this.makeToast("danger", error.response);
      }
    },
    changeValues(produto, oldStr, newStr) {
      // var preco;

      // if (!isNaN(parseFloat(produto.preco))) {
      //   preco = produto.preco.toString().replace(oldStr, newStr);
      // } else {
      //   preco = produto.preco.replace(oldStr, newStr);
      // }

      // this.currentProduto.preco = preco;
      var atacado = produto.preco_atacado.toString().replace(oldStr, newStr);
      this.currentProduto.preco_atacado = atacado;
      var custo = produto.preco_custo.toString().replace(oldStr, newStr);
      this.currentProduto.preco_custo = custo;
      var fatexport = produto.produto_fatexport
        .toString()
        .replace(oldStr, newStr);
      this.currentProduto.produto_fatexport = fatexport;
      var margemlucro = produto.produto_margemlucro
        .toString()
        .replace(oldStr, newStr);
      this.currentProduto.produto_margemlucro = margemlucro;
      var qtd = produto.qtd
        ? produto.qtd.toString().replace(oldStr, newStr)
        : "1,00";
      this.currentProduto.qtd = qtd;
      var pesoliq = produto.produto_pesoliq.toString().replace(oldStr, newStr);
      this.currentProduto.produto_pesoliq = pesoliq;
      var pesobruto = produto.produto_pesobruto
        .toString()
        .replace(oldStr, newStr);
      this.currentProduto.produto_pesobruto = pesobruto;
    },
    seleciona_check(objeto, status, index) {
      console.log("Checkbox selector Selecionado Os Arquivos");
      console.log(objeto);
      console.log(status);
      console.log(index);
      let file = objeto;

      if (status === true) {
        this.readFileContent(file)
          .then((content) => {
            this.fileContent = content;
            objeto.conteudo = content;
            objeto.id = index;
            this.guarda_xmls.push(content);
            //   this.ler_xml();
            this.vetor_new_pre_nfe.push({ ...objeto });
          })
          .catch((error) => {
            console.error("Erro ao ler o conteúdo do arquivo:", error);
          });
      } else if (status !== true) {
        // this.vetor_new_pre_nfe.splice(index,1)
        this.vetor_new_pre_nfe = this.vetor_new_pre_nfe.filter(
          (item) => item.id !== index
        );
      }

      // this.$emit("selecinando_xml_nfe",objeto)
      console.log(this.vetor_new_pre_nfe);
    },
    importa_pedido_xml() {
      this.files_array = [];
      this.upload_bool = true;
      this.boll_arquivos = false;
      document.getElementById("fileInput").click();
    },
    seleciona_xml_fora_bd(objeto, status, index) {
      console.log("estou no selecionda fora do BD");
      //console.log(objeto)
      // console.log(status)
      // console.log(index)
      // if()
      //  // this.files_array = []
      //  if(objeto.file instanceof File){
      //    console.log("tem o obejto")
      //  }

      //  let file= new File(["conteúdo"], `${objeto.name}`, {
      //     type: `${objeto.type}`,
      //     size:`${objeto.size}`
      // })
      // console.log(file)
      console.log(index);
      console.log("mostrando o array sme o check33");
      console.log(this.array_files_sem_check);
      delete objeto.check;
      if (status == true) {
        this.files_array2.push({ id: index });
      } else {
        this.files_array2.splice(index, 1);
      }
      console.log(this.files_array2);
    },
    handleFileChange(event) {
      // não era aqui para fazer esse logica é na função de enviar
      const files = Array.from(event.target.files);
      this.array_files_sem_check = [...files];
      this.files_array = [...files];
      console.log("estou no hanfle mostrando os files");
      console.log(this.files_array);
      console.log(this.files_array[0].textContent);

      //   files.forEach((file) => {
      //     // Verifica se o arquivo é um XML
      //     if (file.type === "text/xml" || file.name.endsWith(".xml")) {
      //       this.readFileContent(file)
      //         .then((content) => {
      //           this.fileContent = content;
      //           this.ler_xml();
      //         })
      //         .catch((error) => {
      //           console.error("Erro ao ler o conteúdo do arquivo:", error);
      //         });
      //     } else {
      //       console.warn("O arquivo não é um XML:", file.name);
      //     }
      //   });
      const promises = files.map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e) => resolve(e.target.result);
          reader.onerror = (e) => reject(e);
          reader.readAsText(file);
        });
      });

      Promise.all(promises)
        .then((contents) => {
          this.xmlContents = contents; // Preenche o array reativo com todos os conteúdos
          console.log("Todos os conteúdos lidos:", this.xmlContents);
          //   this.ver();
        })
        .catch((error) => {
          console.error("Erro ao ler arquivos:", error);
        });

      // const formData = new FormData();
      // this.files_array.forEach(file => {
      //   formData.append('files[]', file);
      // });

      // if (file) {
      //   this.readFileContent(file)
      //     .then((content) => {
      //       this.fileContent = content;
      //       this.ler_xml();
      //     })
      //     .catch((error) => {
      //       console.error("Erro ao ler o conteúdo do arquivo:", error);
      //     });
      // }
      //   this.ver();
    },
    ver() {
      console.log("mosntrnado Conteudos Xmls");
      console.log(this.xmlContents);
      console.log(this.guarda_xmls);
      console.log("nfe Conteudo");
      console.log(this.vetor_new_pre_nfe);
    },
    readFileContent(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (e) => {
          resolve(e.target.result);
        };

        reader.onerror = (e) => {
          reject(e.target.error);
        };

        reader.readAsText(file);
      });
    },
    ler_xml() {
      console.log("estou em ler xml ");
      //   console.log(this.fileContent);
      //   this.xmlContents.push(this.fileContent);
      if (typeof this.fileContent === "string") {
        console.log("é uma srting");
      }
      // xml em string //
      let string_xml = this.fileContent;
      // varivel que vai receber o conteudo convertido de string para xml
      let xmlDocument = new DOMParser().parseFromString(string_xml, "text/xml");
      console.log(xmlDocument);
      let nomes = xmlDocument.querySelectorAll("xProd");
      let listaNomes = [];

      nomes.forEach((nome) => {
        listaNomes.push(nome.textContent);
      });
      console.log(listaNomes);
    },
  },
};
</script>

<style>
</style>